<div class="" id="page-wrapper">
    <div class="container-fluid">
      <div class="row row-stat">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-md-12">
          <div class="panel panel-default panel-table">
            <div class="panel-heading panel-heading-no-divider">Evrak Bilgileri<span class="panel-subtitle">Sisteme kayıtlı gelen giden tüm evraklar listeleniyor.</span>
              <button [hidden]="isAdd || !au.checkPermission('controls:warehouseTransfer:btn:newWarehouseTransfer')" (click)="newRecord()" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-plus"></i> Yeni Transfer</button>
              <button [hidden]="!isAdd" (click)="isAdd=false" class="btn btn-space btn-danger"><i class="icon mdi mdi-close-circle"></i> Vazgeç</button>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <div class="row">
                    <label class="col-sm-3 control-label" for="form-control-2">Başlangıç Tarihi</label>
                    <div class="col-sm-6">
                      <div class="input-group">
                        <input autocomplete="off" [(ngModel)]="startDate" class="form-control" (click)="d.toggle()"
                          placeholder="yyyy-mm-dd" name="dpStart" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                            type="button"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <label class="col-sm-3 control-label" for="form-control-2">Bitiş Tarihi</label>
                    <div class="col-sm-6">
                      <div class="input-group">
                        <input autocomplete="off" [(ngModel)]="endDate" class="form-control" (click)="dEnd.toggle()"
                          placeholder="yyyy-mm-dd" name="dpEnd" ngbDatepicker #dEnd="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="dEnd.toggle()"
                            type="button"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <label class="col-sm-3 control-label" for="form-control-2"> Depo</label>
                    <div class="col-sm-6">
                      <select  [(ngModel)]="selectedWarehouseId" (change)="getTransferList()"  name="selectedWarehouseId" class="form-control">
                        <option [ngValue]="-1">Tümü</option>
                        <option *ngFor="let item of warehouseList" [ngValue]="item.id">{{item.description}}</option>                       
                      </select>
                    </div>
                  </div>
                </div>                <div class="col-md-2">
                  <button class="btn btn-outline-secondary calendar" 
                  type="button" (click)="getTransferList()">Listele</button>
                </div>
              </div>
            </div> 
                <div [hidden]="isAdd" class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                        <tr>
                          <th [hidden]="tableDatePerm">Tarih</th>
                          <th [hidden]="tableDocumentNo">Evrak Numarası</th>
                          <th [hidden]="tablecreateUserName">İşlem Yapan</th>
                          <th [hidden]="tablecreateUserName">Çıkış Depo</th>
                          <th [hidden]="tablecreateUserName">Giriş Depo</th>
                          <th [hidden]="tabledetail">Detay</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of transactionListTmp">
                          <td [hidden]="tableDatePerm">{{item.createDate | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                          <td [hidden]="tableDocumentNo">{{item.documentNo}}</td>
                          <td [hidden]="tablecreateUserName">{{item.createUserName}}</td>
                          <td [hidden]="tablecreateUserName">{{item.from}}</td>
                          <td [hidden]="tablecreateUserName">{{item.to}}</td>
                          <td [hidden]="tabledetail">
                            <button class="btn btn-lg btn-outline-primary" (click)="showModelByName(transferDetail,item.transferList);">Detay</button>
                            <button class="btn btn-lg btn-outline-secondary" (click)="print(item)">Yazdır</button>
                          </td>
                        </tr>
                      </tbody>
                  </table>
                  <div class="d-flex justify-content-between p-2">
                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="refreshCountries()">
                        </ngb-pagination>
                  
                    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" name="pageSize" (ngModelChange)="refreshCountries()">
                      <option [ngValue]="5">5 li listele</option>
                      <option [ngValue]="10">10 lu listele</option>
                      <option [ngValue]="25">25 li listele</option>
                    </select>
                  </div>
                  
                </div>  

                <ng-template #transferDetail let-modal>
                  <table class="table table-striped">
                    <thead>
                        <tr>
                          <th [hidden]="!au.checkPermission('controls:warehouseTransfer:table:date')">Tarih</th>
                          <th [hidden]="!au.checkPermission('controls:warehouseTransfer:table:productCode')">Ürün Kodu</th>
                          <th [hidden]="!au.checkPermission('controls:warehouseTransfer:table:productName')">Ürün Adı</th>
                          <th [hidden]="!au.checkPermission('controls:warehouseTransfer:table:quantity')">Adet</th>
                          <th [hidden]="!au.checkPermission('controls:warehouseTransfer:table:unit')">Birim</th>
                          <th [hidden]="!au.checkPermission('controls:warehouseTransfer:table:transactionType')">Hareket Tipi</th>
                          <th [hidden]="!au.checkPermission('controls:warehouseTransfer:table:warehouseName')">Depo Adı</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of transactionDetailList">
                          <td>{{item.createDate | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                          <td>{{item.productCode}}</td>
                          <td>{{item.productName}}</td>
                          <td>{{item.quantity}}</td>
                          <td>{{item.unit}}</td>
                          <td>{{item.transactionType}}</td>
                          <td>{{item.warehouseName}}</td>
                        </tr>
                      </tbody>
                  </table>
                </ng-template> 
               
          <ng-template #selectProductModal let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Ürün Listesi</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <form>
                  <div class="panel panel-default">
                    <div class="panel-heading panel-heading-no-divider">Ürünler<span class="panel-subtitle">Sistemde kayıtlı tüm ürünler listelenmektedir.</span></div>
                          
                 
                  <input type="text" #searchKey />
                    <button type="button" class="btn btn-outline-dark" (click)="searchProduct(searchKey.value===null ? '' :searchKey.value ,-1)">Ara</button>
              
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th>Ürün Kodu</th>
                                <th>Ürün Adı</th>
                                <th>Adet</th>
                                <th>Birim</th>
                                <th>{{fromWarehouse.description}}</th>
                                <th>{{toWarehouse.description}}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of searchProductList">
                                <td>{{item.code}}</td>
                                <td>{{item.name}}</td>
                                <td><input class="form-control input-pill" [(ngModel)]="item.quantity" type="number" name="quantity"/></td>
                                <td>{{item.unit}}</td>
                                <td>{{getQuantity(item,fromWarehouse.id)}}</td>
                                <td>{{getQuantity(item,toWarehouse.id)}}</td>
                                
                                <td><button class="btn btn-lg btn-outline-primary" (click)="selectTransfer(item);">Seç</button></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                </form>
           
            </div>
            <div class="modal-footer">
            </div>
          </ng-template> 
      
  
                 <div [hidden]="!isAdd" class="panel-body">
                    <div class="xs-mt-10 xs-mb-10  ">
                      <div class="row">
                        <div class="col-md-8">
                          <form class="form-horizontal">
                            <div class="form-group">
                              <div class="row">
                                <label class="col-sm-3 control-label" for="form-control-2">Depo</label>
                                <div class="col-sm-6">
                                  <select  [(ngModel)]="fromWarehouse" name="fromWarehouse" class="form-control">
                                    <option *ngFor="let item of warehouseList" [ngValue]="item">{{item.description}}</option>                       
                                  </select>
                                  </div>
                              </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                  <label class="col-sm-3 control-label" for="form-control-2">Depo</label>
                                  <div class="col-sm-6">
                                    <select  [(ngModel)]="toWarehouse" name="toWarehouse" class="form-control">
                                      <option *ngFor="let item of warehouseList" [ngValue]="item">{{item.description}}</option>                       
                                    </select>
                                    </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="row">
                                  <label class="col-sm-3 control-label" for="form-control-2">Transfer Not</label>
                                  <div class="col-sm-6">
                                    <input  [(ngModel)]="transferNote" name="transferNote" class="form-control" />
                                    </div>
                                </div>
                              </div>
                              
                              <button (click)="selectProductShow()" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-plus"></i> Ürün Ekle</button>
                              <button (click)="addTransfer(false)"  class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-check"></i> Kaydet</button>
                              <div class="panel panel-default">                           
                                <div class="panel-heading panel-heading-no-divider"><span class="panel-subtitle">Eklenen ürünler listeleniyor. </span></div>
                                    <div class="table-responsive">
                                       <table class="table table-striped">
                                        <thead>
                                          <tr>
                                            <th>Ürün Kodu</th>
                                            <th>Ürün Adı</th>
                                            <th>Adet</th>
                                            <th>Birim</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let item of transferList">
                                            <td>{{item.productCode}}</td>
                                            <td>{{item.productName}}</td>
                                            <td>{{item.quantity}}</td>
                                            <td>{{item.unitDesc}}</td>
                                            <td><button (click)="removeTransfer(item)" class="btn btn-lg btn-outline-primary">Sil</button></td>
                                          </tr>
                                        </tbody>
                                      </table> 
                                    </div>
                                  </div>  
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>    
                  <ng-snotify></ng-snotify>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  