import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import {  RouterModule } from '@angular/router';
import { AppRoutingModule, routes } from './app-routing.module';
import { ModalModule } from 'ngb-modal';
import { ProductComponent } from './product/product.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { NgbModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { ServiceModule } from './services/service.modul';
import { ManufactureComponent } from './manufacture/manufacture.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlainComponent } from './plain/plain.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { WarehousetransferComponent } from './warehousetransfer/warehousetransfer.component';
import { ProductsearchComponent } from './productsearch/productsearch.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/Interceptors';
import { AuthGuardService } from './services/loginguard.service';
import { ManufacturecomplateComponent } from './manufacturecomplate/manufacturecomplate.component';
import { SyncComponent } from './sync/sync.component';
import { MainComponent } from './main/main.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UsersComponent } from './users/users.component';
import { OutageComponent } from './outage/outage.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { CustomerComponent } from './customer/customer.component';
import { CurrencyFormat } from './services/custom.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PrescriptionDetailComponent } from './prescription-detail/prescription-detail.component';
import { DatePipe } from '@angular/common';
import { ProductionReportComponent } from './production-report/production-report.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProductComponent,
    ManufactureComponent,
    InvoiceComponent,
    DashboardComponent,
    PlainComponent,
    PrescriptionComponent,
    WarehouseComponent,
    WarehousetransferComponent,
    ProductsearchComponent,
    ManufacturecomplateComponent,
    SyncComponent,
    MainComponent,
    UsersComponent,
    OutageComponent, ConfirmationDialogComponent, CustomerComponent,CurrencyFormat, PrescriptionDetailComponent, ProductionReportComponent
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    RouterModule.forRoot(routes , { useHash: true }),
    AppRoutingModule,
    ModalModule,DataTablesModule,SnotifyModule,NgbTooltipModule,ServiceModule,NgbModule,NgbPaginationModule, 
    FontAwesomeModule, BrowserAnimationsModule ,AngularMaterialModule,NgxSpinnerModule
  ],
  providers: [,
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'TRY ' } ,ConfirmationDialogService,AuthGuardService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },{ provide: 'SnotifyToastConfig', useValue: ToastDefaults},
  SnotifyService,
  DatePipe],
  bootstrap: [AppComponent],schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
