import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDatepicker, NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { SnotifyService } from 'ng-snotify';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { InvoiceDetail } from '../services/invoice.detail.model';
import { Invoice } from '../services/invoice.model';
import { Product } from '../services/product.model';
import { ServiceRepository } from '../services/service.repository';
import { Warehouse } from '../services/warehouse.model';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  constructor(private snotifyService: SnotifyService,private serviceRepository:ServiceRepository,private modalService:NgbModal,private calendar: NgbCalendar,private loading: NgxSpinnerService) { }
  isAdd:boolean=false;
  isDtInitialized:boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})  tableAllList: DataTableDirective;
  @ViewChild('addProductModal', { static: true }) addProductModal: ModalDirective;
  @ViewChild('selectProductModal', { static: true }) selectProductModal: ModalDirective;
  @ViewChild('detailModal', { static: true }) detailModal: ModalDirective;
  invoiceList:Invoice[] = [];
  warehouseList:Warehouse[] = [];
  selectedInvoice:Invoice = new Invoice();
  searchProductList:Product[] = [];
  addItem:InvoiceDetail;
  model: NgbDateStruct;

  
   searchProduct(key:string,type:number){
     this.loading.show();
    this.serviceRepository.productSearchCodeOrName(key,type,-1).subscribe(x=>{
      this.searchProductList =x;
      this.loading.hide();
    });
    }

    selectToday() {
      this.model = this.calendar.getToday();
    }

    modalSelectProduct:NgbModalRef;


newRecord():void{
  this.selectedInvoice = new Invoice();
  this.isAdd = true; 
}


selectProductShow():void{
  this.searchProductList = [];
  this.modalSelectProduct =  this.modalService.open(this.selectProductModal, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
}
modalAddDetail:NgbModalRef;
addProduct():void{
  this.searchProductList = [];
  this.modalAddDetail =  this.modalService.open(this.addProductModal, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
}

removeProduct(item:InvoiceDetail):void{
  this.selectedInvoice.invoiceDetails.splice(this.selectedInvoice.invoiceDetails.indexOf(item),1);
  this.snotifyService.success("İşlem başarılı...");
}

showDetails(item:Invoice):void{
  this.loading.show();
  this.selectedInvoice = item;
  this.serviceRepository.getInvoiceDetailList(item.id).then(x=>{
    this.selectedInvoice.invoiceDetails= x;
    this.modalService.open(this.detailModal, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
    this.loading.hide();
  });
}


addInvoice():void{
  this.loading.show();
  this.selectedInvoice.date = new Date(this.model.year,this.model.month-1,this.model.day);
  this.selectedInvoice.createId=1;
 this.serviceRepository.addInvoice(this.selectedInvoice).then(x=>{
  this.snotifyService.success("İşlem başarılı...");
  this.loading.hide();
 });

}


selectProduct(item:Product):void{
  if(item.quantity<=0){
    this.snotifyService.error("Lütfen 0 dan büyük bir değer giriniz...");
    return;
  }
  this.addItem = {productId:item.id,unitId:item.unitId,quantity:item.quantity,productCode:item.code,productName:item.name,unitDesc:item.unit,createId:1};
  this.selectedInvoice.invoiceDetails.push(this.addItem);
  this.snotifyService.success("İşlem başarılı...");
}

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language:{url:'//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json'}
    };
    this.getInvoiceList();
    this.serviceRepository.getWarehouses().then(x=> this.warehouseList=x);
  }
  rerender():void{
    if (this.isDtInitialized) {
      this.tableAllList.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
  } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
  }
  }

  getInvoiceList():void{
    this.loading.show();
    this.serviceRepository.getInvoiceList().then(x=>{
      this.invoiceList = x;
      this.rerender();
      this.loading.hide();
    });
  }

}
