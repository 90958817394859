<div class="" id="page-wrapper">
    <div class="container-fluid">

        <div class="panel-body">
            <div class="xs-mt-12 xs-mb-12  ">
              <div class="row">
                <div class="col-md-12">
                  <form class="form-horizontal">
                    <div class="form-group">
                      <div class="row">
                        <label class="col-sm-3 control-label" for="form-control-2">Depo</label>
                        <div class="col-sm-6">
                          <select (change)="isResetList=true" [(ngModel)]="fromWarehouse" name="fromWarehouse" class="form-control">
                            <option *ngFor="let item of warehouseList" [ngValue]="item">{{item.description}}</option>                       
                          </select>
                          </div>
                      </div>
                    </div>    
                    <div class="form-group">
                      <div class="row">
                        <label class="col-sm-3 control-label" for="form-control-2">Fire Not</label>
                        <div class="col-sm-6">
                          <input  [(ngModel)]="transferNote" name="transferNote" class="form-control" />
                          </div>
                      </div>
                    </div>                 
                  </form>
                  <form #myForm="ngForm">
                    <app-productsearch [hidden]="fromWarehouse.id===-1" [resetList]="isResetList" [hideQuantity]="false" [zeroQuantity]="true" [warehouseId]="fromWarehouse.id" (setProduct)="selectProduct($event)"></app-productsearch>
                  </form>
                 
                </div>
              </div>
            </div>
          </div>   

<ng-snotify></ng-snotify>
</div></div>