import { Component, OnInit } from '@angular/core';
import { faHome, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  au:AuthService;
  constructor(private auth: AuthService) {this.au=auth; }

  ngOnInit(): void {
  }

  logouts = faSignInAlt;
  home = faHome;
  logout(){
this.auth.logout();
  }
  homes(){
    this.auth.main();
      }
}
