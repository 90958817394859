import { Component,ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {  ModalDirective } from 'ngx-bootstrap/modal';
import {  SnotifyPosition, SnotifyService } from 'ng-snotify';
import { ServiceRepository } from '../services/service.repository';
import { Prescription } from '../services/prescription.model';
import { PrescriptionDetail } from '../services/prescription.detail.model';
import { Product } from '../services/product.model';
import { Warehouse } from '../services/warehouse.model';
import { AuthService } from '../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { AuthPrescription } from '../services/page.auth.model';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '../services/translate.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss'],
})
export class PrescriptionComponent implements OnInit {
  authPage:AuthPrescription = {};
  tableColumns:string[] = [];
  dataSource: MatTableDataSource<Prescription>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private loading: NgxSpinnerService,private translateService:TranslateService,auth: AuthService,private snotifyService: SnotifyService,private serviceRepository:ServiceRepository,private modalService:NgbModal) {
    this.au=auth;
    this.dataSource = new MatTableDataSource();
    this.serviceRepository.getUserPageAuth(this.constructor.name.replace('Component','')).then(x=>{
      this.authPage = x;  
        this.tableColumns = [];
      Object.keys(this.authPage).forEach((key) => {
        if(key.startsWith('table'))
        this.tableColumns.push(key);
      })
    });
   }
   


  warehouseList:Warehouse[] = [];
  prescriptionList : Prescription[] = [];
  prescriptionDetailList : PrescriptionDetail[] = [];
  au:AuthService;
  @ViewChild('detailModal', { static: true }) MyDOMElement: ElementRef;
  @ViewChild('addProductModal', { static: true }) addProductModal: ModalDirective;
  @ViewChild('selectProductModal', { static: true }) selectProductModal: ModalDirective;

  prescriptionListTmp:Prescription[] = [];

applyFilter(filterValue: string) {
  filterValue = filterValue.trim(); // Remove whitespace
  filterValue = filterValue.toLocaleLowerCase(); // Datasource defaults to lowercase matches
  this.dataSource.filter = filterValue;
}
addProduct(isNewDetail:boolean):void{
  this.isNewDetail=isNewDetail;
  this.modalAddDetail =  this.modalService.open(this.addProductModal, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
}

closeResult = '';
isAdd = false;
selectedprescription : Prescription = new Prescription();
searchProductList:Product[] = [];

getWarehouseList():void{
  this.loading.show();
  this.serviceRepository.getWarehouses().then(x=> {this.warehouseList=x;this.loading.hide();});
}


addPrescription():void{
  this.loading.show();
  this.serviceRepository.addPrescription(this.selectedprescription).then(x=>{
    this.loading.hide();
    this.snotifyService.success("İşlem başarılı...");
      this.ngOnInit()
  this.isAdd = false;
  });
}


searchProduct(key:string,type:number){
  this.loading.show();
this.serviceRepository.productSearchCodeOrName(key,type,-1).subscribe(x=>{
  this.searchProductList =x;
  this.loading.hide();
});
}



modalSelectProduct:NgbModalRef;
selectProductShow():void{
  this.modalSelectProduct =  this.modalService.open(this.selectProductModal, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
}
selectProduct(item:Product):void{
  if(item.quantity<=0){
    this.snotifyService.error("Lütfen 0 dan büyük bir değer giriniz...");
    return;
  }
  this.selectedprescription.productId=item.id;
  this.selectedprescription.unitId = item.unitId;
  this.selectedprescription.quantity=item.quantity;
  this.selectedprescription.productCode=item.code;
  this.selectedprescription.productName = item.name;
  this.selectedprescription.unit=item.unit;
  this.selectedprescription.createId=this.au.getUserId();
  this.modalSelectProduct.close();
}


deleteDetail(item:PrescriptionDetail){
  const index: number = this.selectedprescription.prescriptionDetails.indexOf(item);
  this.loading.show();
  if (index !== -1) {
    this.selectedprescription.prescriptionDetails.splice(index,1)
    this.loading.hide();
  };
  if(item.id>0)
this.serviceRepository.deletePrescriptionDetail(item).then(x=>{
  this.serviceRepository.getPrescriptionDetailListById(item.id).then(x=>{
    this.prescriptionDetailList = x;
    this.loading.hide();
  });
});
}
modalAddDetail:NgbModalRef;
deletePrescription(prescription:Prescription,succses:boolean):void{
  if(succses){
    this.loading.show();
    prescription.deleted = true;
    this.serviceRepository.deletePrescription(prescription).then(x=>{  
      this.loading.hide();
      this.snotifyService.success("Silme işlemi tamamlandı.");
      this.ngOnInit();
    });
  }else
  this.snotifyService.confirm('Seçili üretim reçetesi silinecektir. Onaylıyor musunuz?', 'Uyarı!!!', {
    timeout: 10000,
    showProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    buttons: [
      {text: 'Yes', action: () => this.deletePrescription(prescription,true), bold: false},
      {text: 'No', action: () => console.log('Clicked: No')},
    ]
  });
}


open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size:'xl'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

newRecord():void{
  this.getWarehouseList();
  this.selectedprescription = new Prescription();
  this.isAdd = true; 
}

showDetails(item:Prescription):void{
  this.loading.show();
  this.selectedprescription = item;
  this.serviceRepository.getPrescriptionDetailListById(this.selectedprescription.id).then(x=>{
    this.prescriptionDetailList = x;
    this.open(this.MyDOMElement);
    this.loading.hide();
  });

}
isNewDetail:boolean = false;


private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

addItem:PrescriptionDetail;
addDetail(item:Product):void{
  if(item.quantity<=0){
    this.snotifyService.error("Lütfen 0 dan büyük bir değer giriniz...");
    return;
  }
  this.loading.show();
 this.addItem = {id:0,prescriptionId:this.selectedprescription.id,quantity:item.quantity,productId:item.id,unitId:item.unitId,createId:1,unitDesc:item.unit,productCode:item.code,productName:item.name,totalQuantity:0,reserveQuantity:0,spendQuantity:0};
if(this.isNewDetail){
this.selectedprescription.prescriptionDetails.push(this.addItem);
this.loading.hide();
return;
}
}


getPrescriptionList():void{
  this.loading.show();
  this.serviceRepository.getPrescriptions().then(x=>{
    debugger;
    this.prescriptionList = x;
    this.dataSource =new MatTableDataSource(x);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = 
(data: Prescription, filter: string) =>
 (data.productCode.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!=-1 || 
 data.productName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!=-1 ||
 data.createUserName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!=-1 ||
 data.warehouseName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!=-1) ;
    this.paginator._intl.itemsPerPageLabel = this.translateService.getLanguage("Items per page","tr");
    this.paginator._intl.lastPageLabel = this.translateService.getLanguage(this.paginator._intl.lastPageLabel,"tr");
    this.paginator._intl.previousPageLabel = this.translateService.getLanguage(this.paginator._intl.previousPageLabel,"tr");
    this.paginator._intl.nextPageLabel = this.translateService.getLanguage(this.paginator._intl.nextPageLabel,"tr");
    this.loading.hide();
  });
}

ngOnInit(): void {
this.getPrescriptionList();
  }
}
