import { Injectable } from "@angular/core";
import { InvoiceDetail } from "./invoice.detail.model";

@Injectable()
export class Invoice
{
    documentNo:string;
    explanation:string;
    date:Date;
    id:number;
    typeDesc:string;
    type:number;
    warehouseId:number;
    invoiceDetails:InvoiceDetail[] = [];
    createId:number;
}
