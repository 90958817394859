import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { SnotifyService } from 'ng-snotify';
import { Subject } from 'rxjs';
import { Manufacture } from '../services/manufacture.model';
import { PrescriptionDetail } from '../services/prescription.detail.model';
import { Prescription } from '../services/prescription.model';
import { ServiceRepository } from '../services/service.repository';
import { Warehouse } from '../services/warehouse.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ManufacturePlain, UnavailableProducts } from '../services/manufactureplain.model';
import { AuthService } from '../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-manufacture',
  templateUrl: './manufacture.component.html',
  styleUrls: ['./manufacture.component.scss']
})
export class ManufactureComponent implements OnInit {
  constructor(private loading: NgxSpinnerService, private auth: AuthService, private serviceRepository: ServiceRepository, private modalService: NgbModal, private calendar: NgbCalendar, private snotifyService: SnotifyService) { this.au = auth; }
  isAllComplete: boolean = false;
  isDtInitialized: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false }) tableAllList: DataTableDirective;
  @ViewChild('detailModal', { static: true }) detailModal: ModalDirective;
  au: AuthService;
  prescriptionDetailList: PrescriptionDetail[] = [];
  prescriptionList: Prescription[] = [];
  selectedPlain: ManufacturePlain = new ManufacturePlain();
  selectedPrescription: Prescription = new Prescription();
  warehouseList: Warehouse[] = [];
  selectedWarehouseId: number = -1;
  canAdd: boolean = false;
  manufacturePlainList: ManufacturePlain[] = [];
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  unavailableProducts: UnavailableProducts[] = [];
  @ViewChild('unavailableModal', { static: false }) unavailableModal: ModalDirective;
  @ViewChild('prescriptionModal', { static: true }) prescriptionModal: ModalDirective;

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.rerender();
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getWarehouseList(): void {
    this.serviceRepository.getWarehouses().then(x => { this.warehouseList = x; this.loading.hide(); });
  }
  modalPrescriptionDetail: NgbModalRef;
  showPrescription(item: ManufacturePlain): void {
    this.selectedPlain = item;
    this.modalPrescriptionDetail = this.modalService.open(this.prescriptionModal, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
  }

  startManufacture(item: ManufacturePlain, succses: boolean) {
    if (succses) {
      this.loading.show();
      if (!item.isManufacture) {
        this.open(this.unavailableModal);
        this.selectedPlain = item;
        this.loading.hide();
        return false;
      }
      item.createId = this.au.getUserId();
      const manufacture: Manufacture = new Manufacture();
      manufacture.warehouseId = item.warehouseId;
      manufacture.quantity = item.quantity;
      manufacture.manufacturePlainId = item.id;
      this.serviceRepository.startManufacturePlain(manufacture).then(x => {
        this.getManufacturePlainList();
        this.loading.hide();
      });
    }
    else {
      this.snotifyService.confirm('Seçili üretim başlayacaktır. Onaylıyor musunuz?', 'Uyarı!!!', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        buttons: [
          { text: 'Yes', action: () => this.startManufacture(item, true), bold: false },
          { text: 'No', action: () => console.log('Clicked: No') },
        ]
      });
    }

  }

  getManufacturePlainList() {
    this.loading.show();
    let startDate = new Date(this.startDate.year, this.startDate.month - 1, this.startDate.day);
    let endDate = new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day);
    this.serviceRepository.getManufacturePlainListByDate(startDate, endDate, this.selectedWarehouseId).then(x => {
      this.manufacturePlainList = x;
      debugger;
      this.getWarehouseList();
    });
  }

  ngOnInit(): void {
    this.startDate = this.calendar.getToday();
    this.startDate = { year: this.startDate.year, day: 1, month: this.startDate.month };
    this.endDate = this.calendar.getToday();


    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: { url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json' }
    };
    this.getManufacturePlainList();
  }
  rerender(): void {
    if (this.isDtInitialized) {
      this.tableAllList.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }

}
