import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SnotifyPosition, SnotifyService } from 'ng-snotify';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrescriptionDetail } from '../services/prescription.detail.model';
import { Product } from '../services/product.model';
import { ServiceRepository } from '../services/service.repository';

@Component({
  selector: 'app-prescription-detail',
  templateUrl: './prescription-detail.component.html',
  styleUrls: ['./prescription-detail.component.scss']
})
export class PrescriptionDetailComponent implements OnInit {
  @Input() prescriptionId:number = -1;
  @Input() deleteAccess:boolean = false;
  @Input() addAccess:boolean = false;
  @Input() plainQuantity:number = 1;
  @Input() prescriptionName:number = -1;
  constructor(private serviceRepository:ServiceRepository,private loading: NgxSpinnerService,private modalService:NgbModal,private snotifyService: SnotifyService) { }
  prescriptionDetailList:PrescriptionDetail[]=[];
  ngOnInit(): void {
    this.serviceRepository.getPrescriptionDetailListById(this.prescriptionId).then(x=>{
      this.prescriptionDetailList = x;
    });
  }

  deleteDetail(item:PrescriptionDetail){
    this.loading.show();
  this.serviceRepository.deletePrescriptionDetail(item).then(x=>{
    this.serviceRepository.getPrescriptionDetailListById(this.prescriptionId).then(x=>{
      this.prescriptionDetailList = x;
      this.loading.hide();
    });
  });
  }

  printPage(compenentId):void{
    const printContent = document.getElementById(compenentId);
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    // WindowPrt.close();
    }
    @ViewChild('addProductModal', { static: true }) addProductModal: ModalDirective;
    isNewDetail:boolean = false;
    modalAddDetail:NgbModalRef;
    addItem:PrescriptionDetail;

    addProduct(isNewDetail:boolean):void{
      this.isNewDetail=isNewDetail;
      this.modalAddDetail =  this.modalService.open(this.addProductModal, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
    }

    addDetail(item:Product):void{
      if(item.quantity<=0){
        this.snotifyService.error("Lütfen 0 dan büyük bir değer giriniz...");
        return;
      }
      this.loading.show();
     this.addItem = {id:0,prescriptionId:this.prescriptionId,quantity:item.quantity,productId:item.id,unitId:item.unitId,createId:1,unitDesc:item.unit,productCode:item.code,productName:item.name,totalQuantity:0,reserveQuantity:0,spendQuantity:0};

    
    this.serviceRepository.addPrescriptionDetail(this.addItem).then(x=>{
      this.serviceRepository.getPrescriptionDetailListById(this.addItem.prescriptionId).then(r=>{
        this.prescriptionDetailList = <PrescriptionDetail[]>(r);
        this.modalAddDetail.close();    
        this.loading.hide();
        this.snotifyService.success("İşlem başarılı...");
          });
    },error=>{ 
      if(error.status===600){
        this.loading.hide();
        this.snotifyService.confirm('Reçetede '+item.name+' ürününden '+error.error.quantity +' '+error.error.unitDesc+' vardır. Ne yapmak istersiniz?' , 'Uyarı', {
        closeOnClick: false,
        pauseOnHover: true,
        position:SnotifyPosition.centerCenter,
        buttons: [
          {text: 'Mikatarı Güncelle', action: (toast) => {
            this.loading.show();
            this.serviceRepository.updatePrescriptionDetail(this.addItem).then(x=>{  this.snotifyService.remove(toast.id);
            this.serviceRepository.getPrescriptionDetailListById(this.addItem.prescriptionId).then(r=>{
              this.prescriptionDetailList = <PrescriptionDetail[]>(r);
              this.modalAddDetail.close();    
              this.loading.hide();
              this.snotifyService.success("İşlem başarılı...");
                });
              });
          }, bold: false},
          {text: 'Üzerine Ekle', action: (toast) => {
            this.loading.show();
            this.addItem.quantity +=error.error.quantity;
            this.serviceRepository.updatePrescriptionDetail(this.addItem).then(x=>{  this.snotifyService.remove(toast.id);
              this.serviceRepository.getPrescriptionDetailListById(this.addItem.prescriptionId).then(r=>{
              this.prescriptionDetailList = <PrescriptionDetail[]>(r);
              this.modalAddDetail.close();  
              this.loading.hide();  
              this.snotifyService.success("İşlem başarılı...");
                });
            
              });
          }},
          {text: 'İptal', action: (toast) => { this.snotifyService.error("İşlem iptal edildi...");this.snotifyService.remove(toast.id);  }, bold: true },
        ]
      });}
      else{
      this.snotifyService.error("İşlem sırasında bir hata oluştu...");
      this.loading.hide();}
    });
    }
    

  

}
