<div class="main-navigation">
    <ul>
        <li [hidden]="!au.checkPermission('page:customer')">
            <a [routerLink]="['/customer']" [routerLinkActive]="['active']" class="waves-effect">
                <fa-icon [icon]="faPeople"></fa-icon>
                <span>Cari Hesaplar</span>
            </a>
        </li>
        <li [hidden]="!au.checkPermission('page:product')">
            <a [routerLink]="['/product']" [routerLinkActive]="['active']" class="waves-effect">
                <fa-icon [icon]="fabox"></fa-icon>
                <span>Ürünler</span>
            </a>
        </li>
        <!-- <li [hidden]="!au.checkPermission('page:invoice')"><a [routerLink]="['/invoice']" [routerLinkActive]="['active']" class="waves-effect"><i class="fa fa-home"></i> <span
                class="hide-menu">Giriş-Çıkış</span></a></li> -->
        <li [hidden]="!au.checkPermission('page:warehouse')"><a [routerLink]="['/warehouse']"
                [routerLinkActive]="['active']" class="waves-effect">
                <fa-icon [icon]="warehouse"></fa-icon>
                <span>Depolar</span>
            </a>
        </li>
        <li [hidden]="!au.checkPermission('page:warehousetransfer')">
            <a [routerLink]="['/warehousetransfer']" [routerLinkActive]="['active']" class="waves-effect">
                <fa-icon [icon]="fadolly"></fa-icon>
                <span class="hide-menu">Depo Transfer</span>
            </a>
        </li>
        <li [hidden]="!au.checkPermission('page:prescription')">
            <a [routerLink]="['/prescription']" [routerLinkActive]="['active']" class="waves-effect">
                <fa-icon [icon]="clipboard"></fa-icon>
                <span class="hide-menu">Reçeteler</span>
            </a>
        </li>
        <li [hidden]="!au.checkPermission('page:plain')"><a [routerLink]="['/plain']" [routerLinkActive]="['active']"
                class="waves-effect">
                <fa-icon [icon]="calendar"></fa-icon>
                <span class="hide-menu">Planlama</span>
            </a>
        </li>
        <li [hidden]="!au.checkPermission('page:manufacture')">
            <a [routerLink]="['/manufacture']" [routerLinkActive]="['active']" class="waves-effect">
                <fa-icon [icon]="dollytwo"></fa-icon>
                <span>Üretim Başla</span>
            </a>
        </li>
        <li [hidden]="!au.checkPermission('page:manufacturecomplate')">
            <a [routerLink]="['/manufacturecomplate']" [routerLinkActive]="['active']" class="waves-effect">
                <fa-icon [icon]="check"></fa-icon>
                <span>Üretim Tamamla</span>
            </a>
        </li>
        <!-- <li [hidden]="!au.checkPermission('page:sync')">
            <a [routerLink]="['/sync']" [routerLinkActive]="['active']" class="waves-effect">
                <fa-icon [icon]="sync"></fa-icon>
                <span class="hide-menu">Sync</span>
            </a>
        </li> -->
        <li [hidden]="!au.checkPermission('page:users')">
            <a [routerLink]="['/users']" [routerLinkActive]="['active']" class="waves-effect">
                <fa-icon [icon]="users"></fa-icon>
                <span class="hide-menu">Kullanıcılar</span>
            </a>
        </li>
        <li [hidden]="!au.checkPermission('page:outage')">
            <a [routerLink]="['/outage']" [routerLinkActive]="['active']" class="waves-effect">
                <fa-icon [icon]="trashAlt"></fa-icon>
                <span class="hide-menu">Fire</span>
            </a>
        </li>
        <li [hidden]="!au.checkPermission('page:warehouse')"><a [routerLink]="['/production-report']"
                [routerLinkActive]="['active']" class="waves-effect">
                <fa-icon [icon]="clipboard"></fa-icon>
                <span>Raporlar</span>
            </a>
        </li>
        <li [hidden]="!au.checkPermission('page:exit')">
            <a (click)="logOut()" class="waves-effect">
                <fa-icon [icon]="logout"></fa-icon>
                <span class="hide-menu">Çıkış</span>
            </a>
        </li>

    </ul>
</div>