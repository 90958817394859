<div class="" id="page-wrapper">
  <div class="container-fluid">
    <div class="panel-heading panel-heading-no-divider">Planlama Paneli<span class="panel-subtitle">Sistemde üretimi
        planlanmış olan ürünler listelenmektedir.</span>
      <button [hidden]="isAdd || !au.checkPermission('controls:plain:plain:newPlain')" (click)="newRecord()"
        class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-plus"></i> Yeni Ekle</button>
      <button [hidden]="!isAdd" (click)="isAdd=false" class="btn btn-space btn-danger"><i
          class="icon mdi mdi-close-circle"></i> Vazgeç</button>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div *ngIf="!isAdd" class="col-md-12 pl-0 pr-0">
          <ngb-datepicker class="mainCalender" #dp [(ngModel)]="model" [dayTemplate]="tpl"
            (navigate)="getListmanufacturePlain($event)" (ngModelChange)="dateChange()"></ngb-datepicker>

          <ng-template #tpl let-date let-selected="selected">
            <div class="custom-day" [class.greyed]=" days.includes(date.day) && model.month===date.month"
              [class.selected]="selected">{{ date.day }}
            </div>
          </ng-template>
        </div>
        <div *ngIf="isAdd" class="col-md-5 pl-0 pr-0">
          <ngb-datepicker class="mainCalender" #dp [(ngModel)]="model" [dayTemplate]="tpl"
            (navigate)="getListmanufacturePlain($event)" (ngModelChange)="dateChange()"></ngb-datepicker>
          <ng-template #tpl let-date let-selected="selected">
            <div class="custom-day" [class.greyed]=" days.includes(date.day) && model.month===date.month"
              [class.selected]="selected">{{ date.day }}
            </div>
          </ng-template>
        </div>
        <div [hidden]="isAdd" class="col-md-12 pr-0">
          <form>
            <div class="panel panel-default">
              <ng-template #htmlContent class="htmlContent">
                <div style="width: 300px;height: auto;">
                  <div class="col-md-12" *ngFor="let item of warehousesList">
                    <div class="col-md-7">{{item.warehouseDesc}} - {{item.quantity}}</div>
                  </div>
                </div>
              </ng-template>
              <div class="panel-heading panel-heading-no-divider">Üretim Plan Listesi<span
                  class="panel-subtitle"></span>

                <div class="col-md-8">
                  <div class="row">
                    <label class="col-sm-3 control-label" for="form-control-2"> Depo</label>
                    <div class="col-sm-6">
                      <select [(ngModel)]="selectedWarehouseId" (change)="dateChange()" name="selectedWarehouseId"
                        class="form-control">
                        <option [ngValue]="-1">Tümü</option>
                        <option *ngFor="let item of warehouseList" [ngValue]="item.id">{{item.description}}</option>
                      </select>
                    </div>
                  </div>
                </div>

              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th [hidden]="!au.checkPermission('controls:plain:table:planNo')">Plan No</th>
                      <th [hidden]="!au.checkPermission('controls:plain:table:productCode')">Ürün Kodu</th>
                      <th [hidden]="!au.checkPermission('controls:plain:table:productName')">Ürün Adı</th>
                      <th [hidden]="!au.checkPermission('controls:plain:table:plainQuantity')">Planlanan Miktar</th>
                      <th [hidden]="!au.checkPermission('controls:plain:table:manufacturedQuantity')">Üretilen Miktar
                      </th>
                      <th [hidden]="!au.checkPermission('controls:plain:table:unit')">Birim</th>
                      <th [hidden]="!au.checkPermission('controls:plain:table:warehouse')">Depo</th>
                      <th [hidden]="!au.checkPermission('controls:plain:table:delete')">Sil</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of plainList">
                      <td>{{item.documentNo}}</td>
                      <td>{{item.productCode}}</td>
                      <td>{{item.productName}}</td>
                      <td>{{item.quantity}}</td>
                      <td>{{item.complatedQuantity}}</td>
                      <td>{{item.unitDesc}}</td>
                      <td>{{item.warehouseDesc}}</td>
                      <td> <button (click)="deletePlain(item,false)" class="btn btn-space btn-danger"><i
                            class="icon mdi mdi-close-circle"></i> Sil</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
        <div [hidden]="!isAdd" class="col-md-7">
          <form>
            <div class="form-group">
              <div class="row">
                <label class="col-sm-3 control-label" for="form-control-2">Depo Seç</label>
                <div class="col-sm-9">
                  <select [(ngModel)]="addItem.warehouseId" name="warehouseId"
                    (change)="getPrescriptionList(addItem.warehouseId)" class="form-control">
                    <option *ngFor="let item of warehouseList" [ngValue]="item.id">{{item.description}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <label class="col-sm-3 control-label" for="form-control-2">Reçete Seç</label>
                <div class="col-sm-9">
                  <select [(ngModel)]="selectedPrescription" (change)="getPrescriptionDetailList(selectedPrescription)"
                    name="selectedPrescription" class="form-control">
                    <option *ngFor="let item of prescriptionList" [ngValue]="item">{{item.productName}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <label class="col-sm-3 control-label" for="form-control-2">Üretilecek Miktar</label>
                <div class="col-sm-9">
                  <input [(ngModel)]="addItem.quantity" name="quantity" class="form-control input-pill" type="number">
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <label class="col-sm-3 control-label" for="form-control-2">Üretilecek Ürün Kodu</label>
                <div class="col-sm-9">
                  <input [(ngModel)]="addItem.productCode" readonly name="productCode" class="form-control input-pill"
                    type="text">
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <label class="col-sm-3 control-label" for="form-control-2">Üretilecek Ürün Adı</label>
                <div class="col-sm-9">
                  <input [(ngModel)]="addItem.productName" readonly name="productName" class="form-control input-pill"
                    type="text">
                </div>
              </div>
            </div>


          </form>
        </div>
        <div class="col-md-12">
          <div [hidden]="selectedPrescription.prescriptionDetails.length==0" class="panel panel-default mb-25">
            <div class="panel-heading panel-heading-no-divider txt-center"><span
                class="panel-subtitle">{{selectedPrescription.productName}} üretimi için gerekli olan ürünler
                listesi </span></div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Ürün Kodu</th>
                    <th>Ürün Adı</th>
                    <th>Reçete Adet</th>
                    <th>Gerekli Adet</th>
                    <th>Depo Adet</th>
                    <th>Birim</th>
                    <th>Rezerve Adet</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    [ngClass]="item.totalQuantity<(addItem.quantity*item.quantity) ? 'warrningQuantity' : 'quantityOk'"
                    *ngFor="let item of selectedPrescription.prescriptionDetails">
                    <td>{{item.productCode}}</td>
                    <td>{{item.productName}}</td>
                    <td>{{item.quantity}}</td>
                    <td>{{item.quantity*addItem.quantity}}</td>
                    <td>
                      <div [ngbTooltip]="htmlContent" (mouseover)="getQuantityList(item.productId)">
                        {{item.totalQuantity}}
                      </div>
                    </td>
                    <td>{{item.unitDesc}}</td>
                    <td>
                      {{(item.totalQuantity+(item.reserveQuantity-item.spendQuantity))-(item.quantity*addItem.quantity)}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button (click)="addManufacturePlain()" class="btn btn-space btn-success">
              <i class="icon icon-left mdi mdi-plus"></i>
              Üretimi Planla</button>
          </div>
        </div>
      </div>
    </div>
    <div class="date-selector">
      <div class="col-md-12">
        <form class="form-inline">
          <div class="form-group">
            <div class="input-group">
              <input (click)="dStartDate.toggle()" class="form-control" placeholder="yyyy-mm-dd" name="startDate"
                [(ngModel)]="startDate" ngbDatepicker #dStartDate="ngbDatepicker" [placement]="'down'">
            </div>
            <div class="input-group">
              <input (click)="dEndDate.toggle()" class="form-control" placeholder="yyyy-mm-dd" name="endDate"
                [(ngModel)]="endDate" ngbDatepicker #dEndDate="ngbDatepicker" [placement]="'down'">
            </div>
            <button class="btn btn-outline-secondary calendar" (click)="getlistManufacturePlain()"
              type="button">Listele</button>
          </div>
        </form>
      </div>


    </div>
    <div class="col-md-12">
      <div class="col-md-12" style="display: flex;font-weight:bold;" *ngFor="let item of manufactureFuturePlainList">
        {{item.date}}
        <div style="padding-left: 3px;font-weight:bold;" *ngFor="let itemWareHouse of item.wareHouseList">
          {{itemWareHouse.wareHouse}}
          <div style="padding-left: 3px;font-weight:normal !important;"
            *ngFor="let itemPlain of itemWareHouse.plainList">{{itemPlain.productName + ': '+itemPlain.quantity}}
          </div>
        </div>
      </div>
    </div>
    <ng-snotify></ng-snotify>
  </div>
</div>