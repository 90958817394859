import { Component, OnInit } from '@angular/core';
import { ServiceRepository } from '../services/service.repository';

@Component({
  selector: 'app-sync',
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.scss']
})
export class SyncComponent implements OnInit {

  constructor(private serviceRepository:ServiceRepository) { }

  ngOnInit(): void {
    this.serviceRepository.syncProduct().then(x=>{

    });
  }

}
