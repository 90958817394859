<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->
<div class="login-page-full">
<div class="login-image">
  <ul>
    <li><img src="../../assets/images/login-image.jpg"></li>
  </ul>
</div>
<div class="login-content">
  <div class="login-logo"><img src="../../assets/images/logo.png" /></div>
  <div class="login-text">Hesabınıza Giriş Yapın</div>
  <form>
    <div class="alert alert-danger" role="alert" [hidden]="errors.length == 0">
      <ul>
        <li *ngFor="let error of errors">
          {{ error }}
        </li>
      </ul>
    </div>
    <div class="form-field">
      <input type="text" id="userName" [(ngModel)]="userName" class="forms_field-input" name="userName" placeholder="Kullanıcı Adı">
      <label class="form-label" for="Kullanıcı Adı">Kullanıcı Adı</label>
    </div>
    <div class="form-field">
      <input type="password" [(ngModel)]="password" id="password" class="forms_field-input" name="password" placeholder="Şifre">
      <label class="form-label" for="Şifre">Şifre</label>
    </div>
    <div class="form-button">
      <button id="btnLogin" type="submit" (click)="login()" value="Giriş" class="">Giriş Yap</button>
  </div>
  </form>
  <div class="footer-logo">
    <span>
        <img src="../../assets/images/logo-footer.png" /> 
    </span>
    <span>Copyright Eryaz Software</span>
</div>
</div>
<ng-snotify></ng-snotify>  
<ngx-spinner></ngx-spinner>
</div>