import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TokenModel } from './token.model';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }
token:string;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.token = localStorage.getItem("token");
        const authReq = request.clone({ headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ this.token,
        }) });
        return next.handle(request).pipe(t=>{
            return next.handle(authReq); 
        },catchError(err => {
            if ([401].includes(err.status) && this.authService.loggedIn$) {                
                const helper = new JwtHelperService();
                const  token:TokenModel = new TokenModel();
                const decodedToken = helper.decodeToken(this.token);
                const isExpired = helper.isTokenExpired(this.token);
                let refreshToken = localStorage.getItem('refreshToken');
                token.userName = decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
                token.refreshToken = refreshToken;
         this.authService.refreshToken(token);
               };

            // const error = (err && err.error && err.error.message) || err.statusText;
            // console.error(err);
            return throwError(err);
        }))
    }
}