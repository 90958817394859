<header>
    <div class="main-header">
        <div class="logo">
            <img src="/assets/images/logo.png" class="logo" />
        </div>
        <span style="font-weight: bold; font-style: italic;">Merhaba {{au.getNameSurname()}}</span>
        <div>
            <a class="btn btn-social-icon btn-github hidden-sm-down" (click)="homes()">
                <fa-icon [icon]="home"></fa-icon>
                <span>Ana Menü</span>
            </a>        
            <a class="btn btn-social-icon btn-github hidden-sm-down" (click)="logout()">
                <fa-icon [icon]="logouts"></fa-icon>
                <span>Çıkış</span>
            </a>
        </div>
    </div>
</header>