<div style="display: contents;" class="table-responsive">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-3">
                <div class="row">
                    <label class="col-sm-3 control-label" for="form-control-2">Başlangıç Tarihi</label>
                    <div class="col-sm-6">
                        <div class="input-group">
                            <input autocomplete="off" [(ngModel)]="startDate" class="form-control" (click)="d.toggle()"
                                placeholder="yyyy-mm-dd" name="dpStart" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <label class="col-sm-3 control-label" for="form-control-2">Bitiş Tarihi</label>
                    <div class="col-sm-6">
                        <div class="input-group">
                            <input autocomplete="off" [(ngModel)]="endDate" class="form-control" (click)="dEnd.toggle()"
                                placeholder="yyyy-mm-dd" name="dpEnd" ngbDatepicker #dEnd="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dEnd.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <select class="form-select" aria-label="Default select example" [(ngModel)]="warehouseId">
                    <option selected>Depo Seçiniz</option>
                    <option value="6">Led Far Montaj Hattı</option>
                    <option value="18">Çalışma Lambası Üretim Hattı</option>
                    <option value="7">Elektronik Dizgi Üretim Hattı</option>
                    <option value="15">Press Hattı</option>
                    <option value="14">Cnc Hattı</option>
                    <option value="17">Kablo Hattı</option>
                    <option value="16">Enjeksiyon Hattı</option>
                    <option value="20">Bagaj Hattı</option>
                    <option value="22">Park Sensörü Montaj Hattı</option>
                    <option value="27">Led Far Montaj Hattı 2</option>
                    <option value="28">Minyatur Led Ampul Montaj Hattı</option>
                </select>
            </div>
            <div class="col-md-2">
                <button class="btn btn-outline-secondary calendar" type="button"
                    (click)="getQuantityList()">Listele</button>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12">
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" class="form-control ng-valid ng-dirty ng-touched"
            type="text" placeholder="Arama..." />
    </mat-form-field>

    <div style="text-align: right;"> 
        <h4>Toplam Üretilen Adet : {{reportTotalQuantity}}</h4>
    </div>
    <div class="table table-striped">
        <table mat-table class="table table-striped" [dataSource]="dataSource">

            <!-- ID Column -->
            <ng-container matColumnDef="tableCode">
                <th mat-header-cell *matHeaderCellDef> Kod </th>
                <td mat-cell *matCellDef="let row"> {{row.productCode}} </td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="tableName">
                <th mat-header-cell *matHeaderCellDef> Ad </th>
                <td mat-cell *matCellDef="let row"> {{row.productName}} </td>
            </ng-container>

            <ng-container matColumnDef="tableQuantity">
                <th mat-header-cell *matHeaderCellDef> Adet </th>
                <td mat-cell *matCellDef="let row"> {{row.totalQuantity}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
</div>