<div class="" id="page-wrapper">
    <div class="container-fluid">
      <div class="row row-stat">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-md-12">
              <div class="panel panel-default panel-table">
                <div class="panel-heading panel-heading-no-divider">
                  <span>Cariler - Sistemde kayıtlı tüm cariler listelenmektedir.</span>
                  <!-- <div [hidden]="!authCustomer.admin && !authCustomer.btnNewItem">
                    <button [hidden]="isAdd" (click)="isAdd=true" class="btn btn-space btn-success"><i
                        class="icon icon-left mdi mdi-plus"></i> Yeni Ekle</button>
                    <button [hidden]="!isAdd" (click)="isAdd=false" class="btn btn-space btn-danger"><i
                        class="icon mdi mdi-close-circle"></i> Vazgeç</button>
                  </div> -->
                </div>

                <mat-form-field>
                <input matInput (keyup)="applyFilter($event.target.value)" class="form-control ng-valid ng-dirty ng-touched" type="text" placeholder="Arama..."/>
              </mat-form-field>
                
                <div class="table table-striped">
                
                  <mat-table class="table table-striped" [dataSource]="dataSource">
                
                    <!-- ID Column -->
                    <ng-container  matColumnDef="tableCode">
                      <mat-header-cell *matHeaderCellDef> Cari Kodu </mat-header-cell>
                      <mat-cell *matCellDef="let row"> {{row.code}} </mat-cell>
                    </ng-container>
                
                    <!-- Progress Column -->
                    <ng-container matColumnDef="tableName">
                      <mat-header-cell *matHeaderCellDef> Ünvan </mat-header-cell>
                      <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
                    </ng-container>
                
                    <!-- Name Column -->
                    <ng-container matColumnDef="tableAddress">
                      <mat-header-cell *matHeaderCellDef > Adres </mat-header-cell>
                      <mat-cell *matCellDef="let row"> {{row.address}} </mat-cell>
                    </ng-container>
                
                    <!-- Color Column -->
                    <ng-container matColumnDef="tablePhone">
                      <mat-header-cell *matHeaderCellDef > Telefon </mat-header-cell>
                      <mat-cell *matCellDef="let row"> {{row.phone}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="tableBalance">
                        <mat-header-cell *matHeaderCellDef > Bakiye </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.balance | currencyFormat}}  </mat-cell>
                      </ng-container>
                 
  
                    <ng-container  matColumnDef="tableTransaction">
                      <mat-header-cell *matHeaderCellDef> Cari Hareketler </mat-header-cell>
                      <mat-cell *matCellDef="let row"> 
                        <button class="btn btn-lg btn-primary btn-sm btn-block" (click)="getInvoiceList(row)">Cari
                          Hareketler</button>
                      </mat-cell>
                    </ng-container>
                
                    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: tableColumns;">
                    </mat-row>
                  </mat-table>
                
                  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                </div>


                <ng-template #customerInvoice let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">Cari Hareketleri</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div style="display: contents;" class="table-responsive">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-5">
                              <div class="row">
                                <label class="col-sm-3 control-label" for="form-control-2">Başlangıç Tarihi</label>
                                <div class="col-sm-6">
                                  <div class="input-group">
                                    <input autocomplete="off" [(ngModel)]="startDate" class="form-control" (click)="d.toggle()"
                                      placeholder="yyyy-mm-dd" name="dpStart" ngbDatepicker #d="ngbDatepicker">
                                    <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                        type="button"></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="row">
                                <label class="col-sm-3 control-label" for="form-control-2">Bitiş Tarihi</label>
                                <div class="col-sm-6">
                                  <div class="input-group">
                                    <input autocomplete="off" [(ngModel)]="endDate" class="form-control" (click)="dEnd.toggle()"
                                      placeholder="yyyy-mm-dd" name="dpEnd" ngbDatepicker #dEnd="ngbDatepicker">
                                    <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="dEnd.toggle()"
                                        type="button"></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <button class="btn btn-outline-secondary calendar" 
                              type="button" (click)="getInvoices();">Listele</button>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div class="modal-body">
                      <form>
                        <div class="panel panel-default">
                          <div class="panel-heading panel-heading-no-divider">Toplam Bakiye {{totalBalance | currencyFormat}}<span
                              class="panel-subtitle">{{selectedCustomer.name}} firmasına ait cari bakiye listelenmektedir.</span>
                          </div>
                          <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th class="text-center">Detay</th>
                                  <th>Evrak Numarası</th>
                                  <th>Tarih</th>
                                  <th>Belge No</th>
                                  <th>işlem Tipi</th>
                                  <th>Borç</th>
                                  <th>Alacak</th>
                                  <th>Bakiye</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of invoiceList">
                                    <td class="text-center"><span (click)="getInvoiceDetail(item)"><fa-icon [icon]="faPlus" size="lg"></fa-icon></span></td>
                                    <td>{{item.documentNo}}</td>
                                  <td>{{item.date | date: 'dd/MM/yyyy'}}</td>
                                  <td>{{item.explanation}}</td>
                                  <td>{{item.transactionType}}</td>
                                  <td>{{item.debt | currencyFormat}}</td>
                                  <td>{{item.credit | currencyFormat}}</td>
                                  <td>{{item.balance | currencyFormat}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
    
                    </div>
                    <div class="modal-footer">
                    </div>
                  </ng-template>


                  <ng-template #invoiceDetail let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">Fatura Detay</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>                  
                        <div class="modal-body">
                      <form>
                        <div class="panel panel-default">
                          <div class="panel-heading panel-heading-no-divider">Toplam Tutar {{(selectedInvoice.debt>0 ? selectedInvoice.debt : selectedInvoice.credit) | currencyFormat}}<span
                              class="panel-subtitle">{{selectedInvoice.documentNo}} numaralı fatura detayı listelenmektedir.</span>
                          </div>
                          <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Ürün Kodu</th>
                                  <th>Ürün Adı</th>
                                  <th>Miktar</th>
                                  <th>Birim</th>
                                  <th>Fiyat</th>
                                  <th>KDV'li Fiyat</th>
                                  <th>İsk1</th>
                                  <th>İsk2</th>
                                  <th>Tutar</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of invoiceDetailList">
                                    <td>{{item.productCode}}</td>
                                  <td>{{item.productName}}</td>
                                  <td>{{item.quantity}}</td>
                                  <td>{{item.unit}}</td>
                                  <td>{{item.price | currencyFormat}}</td>
                                  <td>{{item.priceWithVat | currencyFormat}}</td>
                                  <td>{{item.discount1}}</td>
                                  <td>{{item.discount2}}</td>
                                  <td>{{item.totalWithVat | currencyFormat}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
    
                    </div>
                    <div class="modal-footer">
                    </div>
                  </ng-template>

  
                <!-- <div [hidden]="!isAdd" class="panel-body">
                  <div class="xs-mt-10 xs-mb-10  ">
                    <div class="row">
                      <div class="col-md-8">
                        <form class="form-horizontal">
                          <div class="form-group">
                            <div class="row">
                              <label class="col-sm-3 control-label" for="form-control-2">Ürün Kodu</label>
                              <div class="col-sm-9">
                                <input [(ngModel)]="product.code" name="code" class="form-control input-pill" type="text">
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="row">
                              <label class="col-sm-3 control-label" for="form-control-2">Ürün Adı</label>
                              <div class="col-sm-9">
                                <input [(ngModel)]="product.name" name="name" class="form-control input-pill" type="text">
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="row">
                              <label class="col-sm-3 control-label" for="form-control-9">Ürün Tipi</label>
                              <div class="col-sm-9">
                                <select [(ngModel)]="product.typeId" name="type" class="form-control">
                                  <option *ngFor="let productType of productTypeList" [ngValue]="productType.id">
                                    {{productType.description}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="row">
                              <label class="col-sm-3 control-label" for="form-control-9">Birim</label>
                              <div class="col-sm-9">
                                <select [(ngModel)]="product.unitId" name="unit" class="form-control">
                                  <option *ngFor="let unit of unitList" [ngValue]="unit.id">{{unit.description}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <button (click)="saveProduct(product)" class="btn btn-space btn-success"><i
                              class="icon icon-left mdi mdi-check"></i> Kaydet</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div> -->
                <ng-snotify></ng-snotify>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>