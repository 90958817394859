import { Injectable } from "@angular/core";
import { PrescriptionDetail } from "./prescription.detail.model";
import { Prescription } from "./prescription.model";

@Injectable()
export class ManufacturePlain {
        id: number;
        warehouseId: number;
        prescriptionId: number;
        productCode: string;
        productName: string;
        quantity: number;
        plainDate: Date;
        plainDateStr: string;
        plainStartDateStr: string;
        documentNo: string;
        startedPerson: string;
        unitDesc: string;
        warehouseDesc: string;
        isManufacture: boolean;
        complatedQuantity: number;
        createId: number;
        status: string;
        unavailableProducts: UnavailableProducts[] = [];
        prescriptionDetail: PrescriptionDetail[] = [];
}

export class UnavailableProducts {
        productCode: string;
        productName: string;
        unitDesc: string;
        quantity: number;
}
