<section>
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Ürün Listesi</h4>
    <button type="button" class="close" aria-label="Close" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <form>
        <div class="panel panel-default">
          <div class="panel-heading panel-heading-no-divider">Ürünler<span class="panel-subtitle">Sistemde kayıtlı tüm ürünler listelenmektedir.</span></div>
          <input type="text" #searchKey />
          <button type="button" class="btn btn-outline-dark" (click)="searchProduct()">ara</button>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Ürün Kodu</th>
                      <th>Ürün Adı</th>
                      <th [hidden]="hideQuantity">Adet</th>
                      <th [hidden]="hideQuantity">Birim</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of searchProductListTmp">
                      <td>{{item.code}}</td>
                      <td>{{item.name}}</td>
                      <td><input class="form-control input-pill" [(ngModel)]="item.quantity" [ngModelOptions]="{standalone: true}" name="quantity" type="number"/></td>
                      <td>{{item.unit}}</td>
                      <td><button class="btn btn-lg btn-outline-primary" (click)="returnProduct(item)">Seç</button></td>
                    </tr> 
                  </tbody>
                </table>
                <div class="d-flex justify-content-between p-2">
                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="refreshCountries()">
                        </ngb-pagination>
                  
                    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" name="pageSize" (ngModelChange)="refreshCountries()">
                      <option [ngValue]="5">5 li listele</option>
                      <option [ngValue]="10">10 lu listele</option>
                      <option [ngValue]="25">25 li listele</option>
                    </select>
                  </div>
              </div>
            </div>
      </form>
 
  </div>
  <div class="modal-footer">
  </div>
</section>