import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepicker,  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnotifyService } from 'ng-snotify';

import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { ManufacturePlain } from '../services/manufactureplain.model';
import { ServiceRepository } from '../services/service.repository';
import { Prescription } from '../services/prescription.model';
import { Warehouse } from '../services/warehouse.model';
import { PlainFuture } from '../services/plain.future.model';
import { AuthService } from '../services/auth.service';
import { CustomDatepickerI18n, I18n } from '../services/translate.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { WarehouseQuantity } from '../services/warehousequantity.model';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
  selector: 'app-plain',
  templateUrl: './plain.component.html',
  styleUrls: ['./plain.component.scss'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class PlainComponent implements OnInit {

  @ViewChild('dp') dp: NgbDatepicker;
  plainList:ManufacturePlain[] = [];
  isAdd:boolean = false;
  selectedPrescription:Prescription = new Prescription();
  canAdd:boolean = false;
  prescriptionList:Prescription[] = [];
  warehouseList:Warehouse[] = [];
  addItem:ManufacturePlain = new ManufacturePlain();
  manufactureFuturePlainList:PlainFuture[] = [];
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  selectedWarehouseId:number = -1;
  spendQuantity:number = 0;
  i:number = -1;
  content:HTMLCollectionOf<Element>;
  
  model: NgbDateStruct;
  constructor(private loading: NgxSpinnerService,private snotifyService: SnotifyService,private serviceRepository:ServiceRepository,private auth: AuthService,private calendar: NgbCalendar,private _i18n: I18n) { this.au=auth;}
  au:AuthService;


  markDisabled2(date: {year: number, month: number, day: number}): boolean {
    return date.day <= 3;
  }  

  newRecord():void{
    this.selectedPrescription = new Prescription();
    this.addItem = new ManufacturePlain();
    this.isAdd = true; 
  }

  addManufacturePlain(){
    this.loading.show();
   this.serviceRepository.addManufacturePlain(this.addItem).then(x=>{
    this.serviceRepository.getManufacturePlainListByDate(new Date(this.model.year,this.model.month-1,this.model.day),new Date(this.model.year,this.model.month-1,this.model.day),this.selectedWarehouseId).then(x=>{this.plainList=x;this.isAdd=false;});
    this.loading.hide();
    this.getlistManufacturePlain();
  })
  }

  warehousesList:WarehouseQuantity[] = [];

  getQuantityList(productId:number){
    this.serviceRepository.getProductQuantityList(productId).then(x=>{
        this.warehousesList = x;
    })
  }

  getPrescriptionDetailList(selectedPrescription:Prescription){
    this.loading.show();
    this.addItem.prescriptionId = selectedPrescription.id;
    this.addItem.productCode = selectedPrescription.productCode;
    this.addItem.productName = selectedPrescription.productName;
    this.addItem.plainDateStr = this.selectedDate.toLocaleDateString();
    this.i=0;

    this.addItem.createId = 1;
    this.serviceRepository.getPrescriptionDetailListByWarehouseId(selectedPrescription.id, this.addItem.warehouseId).then(x=>{
      this.selectedPrescription.prescriptionDetails =x;
      this.canAdd =  this.selectedPrescription.prescriptionDetails.filter(x=>x.totalQuantity<(x.quantity*this.selectedPrescription.quantity)).length>0;
      this.selectedPrescription.prescriptionDetails.forEach(a=>{
        this.serviceRepository.getManuQuantityByProductId(a.productId,this.selectedDate).then(b=>{
          a.reserveQuantity = b;
        });
        this.serviceRepository.getPrescriptionIdByProductId(a.productId,this.selectedDate).then(c=>{
          a.spendQuantity = c;
        });
      });
      this.loading.hide();
    });
}
getPrescriptionList(id:number){
  this.loading.show();
  this.serviceRepository.getPrescriptionListByWarehouseId(id).then(x=>{ 
    this.prescriptionList = x;
    this.loading.hide();
  });
}


deletePlain(plain:ManufacturePlain,succses:boolean):void{
  if(succses){
    this.loading.show();
    this.serviceRepository.removeManufacturePlain(plain).then(x=>{
      this.dateChange();
      this.getlistManufacturePlain();
      this.loading.hide();
      this.snotifyService.success("Silme işlemi tamamlandı.");
    });
  }else
  this.snotifyService.confirm('Seçili üretim planı silinecektir. Onaylıyor musunuz?', 'Uyarı!!!', {
    timeout: 10000,
    showProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    buttons: [
      {text: 'Yes', action: () => this.deletePlain(plain,true), bold: false},
      {text: 'No', action: () => console.log('Clicked: No')},
    ]
  });
}


getlistManufacturePlain():void{
  this.loading.show();
  this.serviceRepository.getManufactureFuturePlainList(new Date(this.startDate.year,this.startDate.month-1,this.startDate.day),new Date(this.endDate.year,this.endDate.month-1,this.endDate.day)).then(x=>{  
    this.manufactureFuturePlainList=x;
    this.loading.hide();
   });
}
  
  ngOnInit(): void {
    this.loading.show();
    this.startDate = this.calendar.getToday();
    this.endDate = this.calendar.getNext(this.calendar.getToday(), 'd', 7);
    this.model = this.calendar.getToday();
   this.selectedDate = new Date(this.model.year,this.model.month-1,this.model.day);


   this.serviceRepository.getWarehouses().then(x=> 
    {this.warehouseList=x;  
      this.serviceRepository.getManufacturePlainListByYearAndMonth(this.model.year,this.model.month).then(t=>{
        this.days=t;
       this.serviceRepository.getManufacturePlainListByDate(new Date(this.model.year,this.model.month-1,this.model.day),new Date(this.model.year,this.model.month-1,this.model.day),this.selectedWarehouseId).then(x=>{
         this.plainList=x;
         this.loading.hide();
   this.getlistManufacturePlain();
        });
     });
    
    
    });



  
  }
  selectedDate:Date;
days:number[] = [];

getListmanufacturePlain(event):void{
  this.loading.show();
  const targetMonth = event.next.month;
  const targetYear = event.next.year;
  this.model.month=targetMonth;

  this.serviceRepository.getManufacturePlainListByYearAndMonth(targetYear,targetMonth).then(t=>{
    this.days=t;
    this.loading.hide();
  });
}

  dateChange():void{
    this.selectedDate = new Date(this.model.year,this.model.month-1,this.model.day);
    this.loading.show();
    this.serviceRepository.getManufacturePlainListByYearAndMonth(this.model.year,this.model.month).then(t=>{
      this.days=t;
      this.serviceRepository.getManufacturePlainListByDate(new Date(this.model.year,this.model.month-1,this.model.day),new Date(this.model.year,this.model.month-1,this.model.day),this.selectedWarehouseId).then(x=>
        { 
          this.plainList=x;
          this.loading.hide();
         });
    });



  }

  
}
