<div class="" id="page-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="panel-heading panel-heading-no-divider">Kullanıcılar
        <button [hidden]="isAdd" (click)="newUser()" class="btn btn-space btn-success"><i
            class="icon icon-left mdi mdi-plus"></i> Yeni Ekle</button>
        <button [hidden]="!isAdd" (click)="isAdd=false" class="btn btn-space btn-danger"><i
            class="icon mdi mdi-close-circle"></i> Vazgeç</button>
      </div>
    </div>
    <div class="row row-stat">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-md-4 pl-0 pr-0">
            <div class="user-list">
              <span>Kullanıcı Listesi</span>
              <ul>
                <li (click)="selectUser(item)" *ngFor="let item of userList">{{item.name}}</li>
              </ul>
            </div>
          </div>
          <div class="col-md-8">
            <div [hidden]="isAdd">
              <div class="form-group">
                <div style="    text-align: right;"> <button [hidden]="!(selectedUser.id>0)" (click)="removeUser(false)" class="btn btn-space btn-danger"><i
                  class="icon mdi mdi-close-circle"></i> Sil</button></div>
               
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">Kullanıcı Adı </label>
                  <div class="col-sm-9">
                    <input disabled='{{!isSelected}}' type="text" [(ngModel)]="selectedUser.userName" class="form-control">
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">Şifre </label>
                  <div class="col-sm-9">
                    <input disabled='{{!isSelected}}' type="text" [(ngModel)]="selectedUser.password" class="form-control">
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">Adı </label>
                  <div class="col-sm-9">
                    <input disabled='{{!isSelected}}' type="text" [(ngModel)]="selectedUser.name" class="form-control">
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">SoyAdı </label>
                  <div class="col-sm-9">
                    <input disabled='{{!isSelected}}' type="text" [(ngModel)]="selectedUser.surName" class="form-control">
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">Telefon </label>
                  <div class="col-sm-9">
                    <input disabled='{{!isSelected}}' type="text" [(ngModel)]="selectedUser.phone" class="form-control">
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">E-Mail </label>
                  <div class="col-sm-9">
                    <input disabled='{{!isSelected}}' type="text" [(ngModel)]="selectedUser.email" class="form-control">
                  </div>
                </div>

                <div class="row">
                  <div *ngFor="let item of userRoles" class="form-check">
                    <input (change)="editOrAddRole(item)" class="form-check-input" type="checkbox" [(ngModel)]="item.selected"  [ngModelOptions]="{standalone: true}" id="{{item.appRoleId}}">
                    <label class="form-check-label" for="{{item.appRoleId}}">
                      {{item.name}}
                    </label>
                  </div>
                </div>

                <div class="row">
                  <div *ngFor="let item of warehouseList" class="form-check">
                    <input (change)="editOrAddWarehouseOwner(item)" class="form-check-input" type="checkbox" [(ngModel)]="item.selected"  [ngModelOptions]="{standalone: true}" id="{{item.warehouseId}}">
                    <label class="form-check-label" for="{{item.warehouseId}}">
                      {{item.description}}
                    </label>
                  </div>
                </div>

              </div>
              <div>
                <h5>Yetkilendirme</h5>
                <ngb-accordion [closeOthers]="true" (panelChange)="beforeChange($event)">
                  <ngb-panel id="preventchange-{{item.controls.length===0 ? 0 : item.id}}" *ngFor="let item of userAuthorizations">
                    <ng-template ngbPanelHeader>
                      <div class="d-flex align-items-center justify-content-between">
                         <button ngbPanelToggle style="border:0;background:none;">{{item.header}}</button>
                        <input (change)="editOrAddAuthorization(item,item.id)" class="form-check-input" type="checkbox" [(ngModel)]="item.selected"  [ngModelOptions]="{standalone: true}" id="{{item.id}}">
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>                    
                      <div *ngFor="let itemControl of item.controls" class="form-check">
                        <input (change)="editOrAddAuthorization(itemControl,item.id)" class="form-check-input" type="checkbox" [(ngModel)]="itemControl.selected"  [ngModelOptions]="{standalone: true}" id="{{itemControl.id}}">
                        <label class="form-check-label" for="{{itemControl.id}}">
                          {{itemControl.header}}
                        </label>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
            </div>
            <div [hidden]="!isAdd">
              <div class="form-group">
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">Kullanıcı Adı </label>
                  <div class="col-sm-9">
                    <input type="text" [(ngModel)]="selectedUser.userName" class="form-control">
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">Şifre </label>
                  <div class="col-sm-9">
                    <input type="text" [(ngModel)]="selectedUser.password" class="form-control">
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">Adı </label>
                  <div class="col-sm-9">
                    <input type="text" [(ngModel)]="selectedUser.name" class="form-control">
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">SoyAdı </label>
                  <div class="col-sm-9">
                    <input type="text" [(ngModel)]="selectedUser.surName" class="form-control">
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">Telefon </label>
                  <div class="col-sm-9">
                    <input type="text" [(ngModel)]="selectedUser.phone" class="form-control">
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-3 control-label" for="form-control-2">E-Mail </label>
                  <div class="col-sm-9">
                    <input type="text" [(ngModel)]="selectedUser.email" class="form-control">
                  </div>
                </div>
              </div>
            </div>
<div class="col-md-12">  <button (click)="addOrEditUser(false)" class="btn btn-space btn-success btn-block"><i
  class="icon icon-left mdi mdi-plus"></i> Kaydet</button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-snotify></ng-snotify>
</div>