import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { SnotifyService } from 'ng-snotify';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Manufacture, ReturnManufacture } from '../services/manufacture.model';
import { ServiceRepository } from '../services/service.repository';
import { Warehouse } from '../services/warehouse.model';

@Component({
  selector: 'app-manufacturecomplate',
  templateUrl: './manufacturecomplate.component.html',
  styleUrls: ['./manufacturecomplate.component.scss']
})
export class ManufacturecomplateComponent implements OnInit {

  constructor(private loading: NgxSpinnerService,private auth: AuthService,private snotifyService: SnotifyService,private serviceRepository:ServiceRepository,private calendar: NgbCalendar) { this.au=auth;}
manufactureList:ReturnManufacture;
clickIsFinished=true;

au:AuthService;
startDate: NgbDateStruct;
endDate: NgbDateStruct;
isDtInitialized:boolean = false;
dtOptions: DataTables.Settings = {};
dtTrigger: Subject<any> = new Subject<any>();
@ViewChild(DataTableDirective, {static: false})  tableAllList: DataTableDirective;
warehouseList:Warehouse[] = [];


getWarehouseList():void{
  this.serviceRepository.getWarehouses().then(x=> {this.warehouseList=x;this.loading.hide();});
}

complete(manufacture:Manufacture,succses:boolean){
  if(manufacture.completeQuantity<=0){
  this.snotifyService.error("Lütfen 0 dan büyük değer giriniz...");
  return false;
}
else if(manufacture.quantity-manufacture.completedQuantity < manufacture.completeQuantity){
  this.snotifyService.error("Kalan miktardan fazla miktar girilemez...");
  return false;
}
  else if(succses){
    if(this.clickIsFinished){
      this.loading.show();
      this.clickIsFinished = false;
  this.serviceRepository.completeManufacture(manufacture).then(x=>{
    let startDate = new Date(this.startDate.year,this.startDate.month-1,this.startDate.day);
  let endDate = new Date(this.endDate.year,this.endDate.month-1,this.endDate.day);
    this.serviceRepository.getManufactures(startDate,endDate,this.selectedWarehouseId).then(x=>{
      this.manufactureList = x;
      this.snotifyService.success("İşlem başarılı...");
      this.rerender();
      this.clickIsFinished = true;
      this.loading.hide();
    });
  })}}
  else
  {
    this.snotifyService.confirm('Seçili üretim '+manufacture.completeQuantity+' adet tamamlanacaktır. Onaylıyor musunuz?', 'Uyarı!!!', {
      timeout: 10000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {text: 'Yes', action: () => this.complete(manufacture,true), bold: false},
        {text: 'No', action: () => console.log('Clicked: No')},
      ]
    });
  }
}
selectedWarehouseId:number=-1;
getManufactures():void{
  this.loading.show();
  let startDate = new Date(this.startDate.year,this.startDate.month-1,this.startDate.day);
  let endDate = new Date(this.endDate.year,this.endDate.month-1,this.endDate.day);
  this.serviceRepository.getManufactures(startDate,endDate,this.selectedWarehouseId).then(x=>{
    this.manufactureList = x;
    this.getWarehouseList();
  });
}


  ngOnInit(): void {
   // this.startDate = this.calendar.getToday();

    let date:Date = new Date();
    date.setDate(date.getDate()-7);

    this.startDate = {year:date.getFullYear(),day:date.getDate(),month:date.getMonth()+1};
    this.endDate = this.calendar.getToday();
  this.getManufactures();
  }
  rerender():void{
    if (this.isDtInitialized) {
      this.tableAllList.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
  } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
  }
  
  }

}
