import { Component, OnInit } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceRepository } from '../services/service.repository';
import { User } from '../services/user.model';
import { UserAuthorization } from '../services/userauthorization.model';
import { UserRole } from '../services/userrole.model';
import { WarehouseOwner } from '../services/warehouseowner.model';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  isAdd: boolean = false;
  isSelected:boolean = false;

  constructor(private loading: NgxSpinnerService,private snotifyService: SnotifyService,private serviceRepository:ServiceRepository) { }
  userList:User[] = [];
  selectedUser:User = {id:0,email:'',name:'',password:'',phone:'',surName:'',userName:'',appUserRoles:null,authorizations:null,deleted:false};
  userRoles:UserRole[] = [];
  userAuthorizations:UserAuthorization[] = [];
  warehouseList:WarehouseOwner[] = [];

  public beforeChange($event: NgbPanelChangeEvent) {
    if ($event.panelId === 'preventchange-0') {
      $event.preventDefault();
    }
  }
  
  editOrAddAuthorization(item:UserAuthorization,id:number):void{
    this.loading.show();
    item.appUserId=this.selectedUser.id;
    this.serviceRepository.editOrAddAuthorization(item).then(x=>{
        if(item.isPage){
        item.controls.forEach(element => {
         element.selected=item.selected;
        });
      }
      else if(item.controls.length===0 && !item.selected){
      var pageItem =  this.userAuthorizations.filter(x=>x.id==id && x.controls.filter(t=>t.selected==true).length==0);
      if(pageItem.length===1)
      pageItem[0].selected=false;
      }
      else if(item.controls.length===0 && item.selected){
        var pageItem =  this.userAuthorizations.filter(x=>x.id==id && !x.selected);
        if(pageItem.length===1)
        pageItem[0].selected=true;
        }
        this.loading.hide();
      this.snotifyService.success("İşlem başarılı...");
    });
  }

  editOrAddRole(item:UserRole):void{
    this.loading.show();
    item.appUserId=this.selectedUser.id;
    this.serviceRepository.editOrAddRole(item).then(x=>{
      this.loading.hide();
        this.snotifyService.success("İşlem başarılı...");
    });
  }

  editOrAddWarehouseOwner(item:WarehouseOwner):void{
    this.loading.show();
    item.ownerId=this.selectedUser.id;
    this.serviceRepository.editOrAddWarehouseOwner(item).then(x=>{
      this.loading.hide();
        this.snotifyService.success("İşlem başarılı...");
    });
  }

  removeUser(succsess:boolean):void{
    if(succsess){
this.selectedUser.deleted=true;
this.addOrEditUser(true);
    }
    else
    this.snotifyService.confirm('Seçili kullanıcı silinecektir. Onaylıyor musunuz?', 'Uyarı!!!', {
      timeout: 10000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {text: 'Yes', action: () => this.removeUser(true), bold: false},
        {text: 'No', action: () => console.log('Clicked: No')},
      ]
    });
  }
  
  selectUser(item:User):void{
    this.loading.show();
    this.selectedUser=item;
    this.isSelected=true;
    this.serviceRepository.getUserAuthorizationListByUserId(item.id).then(x=>{
      this.userAuthorizations = x;
      this.serviceRepository.getUserRoleListByUserId(item.id).then(x=>{
        this.userRoles = x;
        this.serviceRepository.getWarehouseListByUserId(item.id).then(x=>{
          this.warehouseList = x;
          this.loading.hide();
        });  
      });
    });

  }

  addOrEditUser(isDeleted:boolean):void{
    this.loading.show();
    this.serviceRepository.addOrEditUser(this.selectedUser).then(xUser=>{
      this.serviceRepository.getUserList().then(x=>{
        this.userList = x;
        this.isAdd=false;
        if(!isDeleted)
        this.selectUser(xUser);
        else{
          this.userRoles = [];
          this.userAuthorizations = [];
          this.warehouseList = [];
        this.selectedUser={id:0,email:'',name:'',password:'',phone:'',surName:'',userName:'',appUserRoles:null,authorizations:null,deleted:false};
      }
      this.loading.hide();
        this.snotifyService.success("İşlem başarılı...");
      })
    }).catch(x=> {this.snotifyService.error(x.error);this.loading.hide();})
  }

  ngOnInit(): void {
    this.serviceRepository.getUserList().then(x=>{
      this.userList = x;
    })
  }

  newUser():void{
    this.selectedUser={id:0,email:'',name:'',password:'',phone:'',surName:'',userName:'',appUserRoles:null,authorizations:null,deleted:false};
    this.isAdd = true; 
  }

}
