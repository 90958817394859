<div class="" id="page-wrapper">
    <div class="container-fluid">
      <div class="row row-stat">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-md-12">
          <div class="panel panel-default panel-table">
            <div class="panel-heading panel-heading-no-divider">Depolar<span class="panel-subtitle">Sistemde kayıtlı depolar listelenmektedir.</span>
              <button [hidden]="isAdd || !au.checkPermission('controls:warehouse:btn:newWarehouse')" (click)="newRecord()" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-plus"></i> Yeni Ekle</button>
              <button [hidden]="!isAdd" (click)="isAdd=false" class="btn btn-space btn-danger"><i class="icon mdi mdi-close-circle"></i> Vazgeç</button>
            </div>
            
                <div [hidden]="isAdd" class="table-responsive">
                    <table #dataTable datatable [dtOptions]="dtOptions"  [dtTrigger]="dtTrigger" class="row-border hover">
                        <thead>
                          <tr>
                            <th [hidden]="!au.checkPermission('controls:warehouse:table:id')">Id</th>
                            <th [hidden]="!au.checkPermission('controls:warehouse:table:warehouseCode')">Depo Kodu</th>
                            <th [hidden]="!au.checkPermission('controls:warehouse:table:warehouseName')">Depo Adı</th>
                            <th [hidden]="!au.checkPermission('controls:warehouse:table:stocks')"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let warehouse of warehouseList">
                            <td>{{warehouse.id}}</td>
                            <td>{{warehouse.code}}</td>
                            <td>{{warehouse.description}}</td>
                            <td><button (click)="getQuantityListById(warehouse)" class="btn btn-lg btn-primary btn-sm btn-block"> Ürünler</button></td>
                          </tr>
                         
                        </tbody>
                      </table>
                </div>

                 <div [hidden]="!isAdd" class="panel-body">
                    <div class="xs-mt-10 xs-mb-10  ">
                      <div class="row">
                        <div class="col-md-8">
                          <form class="form-horizontal">
                            <div class="form-group">
                              <div class="row">
                                <label class="col-sm-3 control-label" for="form-control-2">Depo Kodu</label>
                                <div class="col-sm-9">
                                  <input [(ngModel)]="warehouse.code" name="code" class="form-control input-pill" type="text">
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                  <label class="col-sm-3 control-label" for="form-control-2">Depo Adı</label>
                                  <div class="col-sm-9">
                                    <input [(ngModel)]="warehouse.description" name="description" class="form-control input-pill" type="text">
                                  </div>
                                </div>
                              </div>
                            
                            
                              <button (click)="saveWarehouse(warehouse)" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-check"></i> Kaydet</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <ng-snotify></ng-snotify>


                  <ng-template #productTransaction let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">Stok Hareketleri</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
    
                    <div style="display: contents;" class="table-responsive">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-5">
                            <div class="row">
                              <label class="col-sm-3 control-label" for="form-control-2">Başlangıç Tarihi</label>
                              <div class="col-sm-6">
                                <div class="input-group">
                                  <input autocomplete="off" [(ngModel)]="startDate" class="form-control" (click)="d.toggle()"
                                    placeholder="yyyy-mm-dd" name="dpStart" ngbDatepicker #d="ngbDatepicker">
                                  <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                      type="button"></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="row">
                              <label class="col-sm-3 control-label" for="form-control-2">Bitiş Tarihi</label>
                              <div class="col-sm-6">
                                <div class="input-group">
                                  <input autocomplete="off" [(ngModel)]="endDate" class="form-control" (click)="dEnd.toggle()"
                                    placeholder="yyyy-mm-dd" name="dpEnd" ngbDatepicker #dEnd="ngbDatepicker">
                                  <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="dEnd.toggle()"
                                      type="button"></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <button class="btn btn-outline-secondary calendar" 
                            type="button" (click)="getTransactions();">Listele</button>
                          </div>
                        </div>
                      </div>
                    </div>
    
    
    
                    <div class="modal-body">
                      <form>
                        <div class="panel panel-default">
                          <div class="panel-heading panel-heading-no-divider">{{selectedWarehouseQuantity.product.code}}<span
                              class="panel-subtitle">{{selectedWarehouseQuantity.product.name}} ürnüne ait hareketler listelenmektedir.</span>
                          </div>
                          <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Tarih</th>
                                  <th>Ürün Kodu</th>
                                  <th>Ürün Adı</th>
                                  <th>Adet</th>
                                  <th>Birim</th>
                                  <th>İşlem</th>
                                  <th>Depo Adı</th>
                                  <th>Hareket Tipi</th>
                                  <th>İşlemi Yapan</th>
                                  <th>Evrak No</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of transactionList">
                                  <td>{{item.createDate | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                                  <td>{{item.productCode}}</td>
                                  <td>{{item.productName}}</td>
                                  <td>{{item.quantity}}</td>
                                  <td>{{item.unit}}</td>
                                  <td>{{item.type}}</td>
                                  <td>{{item.warehouseName}}</td>
                                  <td>{{item.transactionType}}</td>
                                  <td>{{item.createUserName}}</td>
                                  <td>{{item.documentNo}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
    
                    </div>
                    <div class="modal-footer">
                    </div>
                  </ng-template>

                  <ng-template #quantityModal let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">Depodaki Ürünler</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form>
                        <div class="panel panel-default">
                          <div class="panel-heading panel-heading-no-divider">{{warehouse.code}}<span class="panel-subtitle">{{warehouse.description}} deposundaki ürünler listelenmektedir. </span></div>
                               <div class="table-responsive">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>Ürün Kodu</th>
                                      <th>Ürün Adı</th>
                                      <th>Miktar</th>
                                      <th>Birim</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr [ngClass]="item.criticalLevelInfo ? 'bgColorRed' : ''" *ngFor="let item of warehouseQuantityList">
                                      <td>{{item.product.code}}</td>
                                      <td>{{item.product.name}}</td>
                                      <td>{{item.quantity}}</td>
                                      <td>{{item.product.unit}}</td>
                                      <td><button class="btn btn-sm btn-secondary" (click)="showTransactionModal(item);">Stok Hareketleri</button></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                      </form>
                    </div>
                    <div class="modal-footer">
              
                    </div>
                  </ng-template>  

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
