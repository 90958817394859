import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth.service';
import { Login } from '../services/Login.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
loginItem:Login = new Login();
userName:string="";
password:string="";
  constructor(private auth:AuthService, private router: Router,private snotifyService: SnotifyService,private spinner: NgxSpinnerService) { }
  errors = [];
  ngOnInit(): void {

    this.auth.isLoggedIn().then(x=>{
      if(x){
        this.router.navigate(['main']);
        return  ;
      }
    })

  
  }
  login():void{
    this.spinner.show();
this.loginItem.userName=this.userName;
this.loginItem.password=this.password;
    this.auth.loginOl(this.loginItem).then(x=>{
       if(x.success)
    this.router.navigate(['main']);
    else{
      this.errors = [];
      if (x.message.error.status === 400) {
        let validationErrorDictionary = x.message.error.errors;
        for (var fieldName in validationErrorDictionary) {
          if (validationErrorDictionary.hasOwnProperty(fieldName)) {
            this.errors.push(validationErrorDictionary[fieldName]);
          }
        }
      } else {
        this.snotifyService.error(x.message.error);
      }
    }
    this.spinner.hide();
    });
  }
}
