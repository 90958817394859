import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ProductTransaction } from '../services/productTransaction.model';
import { ServiceRepository } from '../services/service.repository';
import { Warehouse } from '../services/warehouse.model';
import { WarehouseQuantity } from '../services/warehousequantity.model';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {

  constructor(private loading: NgxSpinnerService,private serviceRepository:ServiceRepository,private modalService:NgbModal,private authService:AuthService) { this.au=authService;}
  au:AuthService;
  @ViewChild('quantityModal', { static: true }) quantityModal: ModalDirective;
  warehouseList:Warehouse[] =[];
  warehouseQuantityList:WarehouseQuantity[] =[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})  dtElement: DataTableDirective;
  @ViewChild('productTransaction', { static: false }) productTransaction: ModalDirective;
  isDtInitialized:boolean = false;
  isAdd:boolean = false;
  warehouse:Warehouse = new Warehouse();
  transactionList:ProductTransaction[] = [];
  getList():void{
    this.loading.show();
      this.serviceRepository.getWarehouses().then(x=>{this.warehouseList =x; this.rerender();this.loading.hide();});
  }
  modalDetail:NgbModalRef;
  getQuantityListById(item:Warehouse):void{
    this.loading.show();
    this.warehouse = item;
    this.modalDetail =  this.modalService.open(this.quantityModal, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
      this.serviceRepository.getWarehouseQuantityListById(item.id).then(x=>
        
        {this.warehouseQuantityList = x;
          debugger;
          this.loading.hide();}
        );
  }
  saveWarehouse(item:Warehouse):void{
    this.loading.show();
    this.serviceRepository.addWarehouse(item).then(x=>{
      this.getList();
      this.loading.hide();
      this.isAdd = false;
    });
  }

  
  newRecord():void{
    this.warehouse = new Warehouse();
    this.isAdd = true; 
  }
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  getTransactions():void{
    this.loading.show();
    let startDate = new Date(this.startDate.year,this.startDate.month-1,this.startDate.day);
    let endDate = new Date(this.endDate.year,this.endDate.month-1,this.endDate.day);
    let productId = this.selectedWarehouseQuantity.product.id;
    this.serviceRepository.getProductTransactionListByDateAndProductId(startDate,endDate,productId).then(x=>{
      this.transactionList = x;
      this.loading.hide();
    });
  }
selectedWarehouseQuantity:WarehouseQuantity;
  showTransactionModal(item:WarehouseQuantity):void{
    this.selectedWarehouseQuantity = item;
    let date:Date = new Date();
    this.endDate = {year:date.getFullYear(),day:date.getDate(),month:date.getMonth()+1};
    date.setDate(date.getDate()-7);
    this.startDate = {year:date.getFullYear(),day:date.getDate(),month:date.getMonth()+1};


    this.getTransactions();
    this.modalService.open(this.productTransaction, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
  }


  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language:{url:'//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json'}
    };
    this.getList();
  }

  rerender():void{
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
  } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
  }
  }

}
