import { Component, OnInit } from '@angular/core';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import {  faBoxOpen, faCheckSquare, faClipboardList, faCoffee, faDolly,  faPeopleArrows,  faPeopleCarry, faPersonBooth, faSync, faTrashAlt, faUsers, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  au:AuthService;
  constructor(private auth: AuthService) {this.au=auth; }
  ngOnInit(): void {
  }

  logOut(){
    this.auth.logout();
      }

  faCoffee = faCoffee;
  faCheckSquare=faCheckSquare;
  fabox = faBoxOpen;
  faPeople=faPeopleArrows;
  warehouse = faWarehouse;
  fadolly = faDolly;
  clipboard = faClipboardList;
  calendar = faCalendarAlt;
  dollytwo = faPeopleCarry;
  check = faCheckSquare;
  sync = faSync;
  logout = faPersonBooth;
  users=faUsers;
  trashAlt=faTrashAlt;

}
