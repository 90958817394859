import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedComponent } from './shared.component';
import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
HeaderComponent,
NavigationComponent,
SharedComponent
  ],
  imports: [
  CommonModule,
  RouterModule.forChild([]),
  BrowserModule,
  FormsModule  ,
  RouterModule  ,
  FontAwesomeModule
  ],
  exports:[SharedComponent],

})
export class SharedModule { }
