import { Injectable } from "@angular/core";
import { WarehouseQuantity } from "./warehousequantity.model";

@Injectable()
export class Product
{
    id:number;
    code:string;
    name:string;
    unit:string;
    typeId:number;
    unitId:number;
    criticalLevel:number;
    createId:number;
    quantity:number;
    warehouseQuantitys:WarehouseQuantity[]=[];
    type:string;
    warehouseId:number;
    criticalLevelInfo:boolean;
}
