import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Product } from '../services/product.model';
import { ServiceRepository } from '../services/service.repository';

@Component({
  selector: 'app-productsearch',
  templateUrl: './productsearch.component.html',
  styleUrls: ['./productsearch.component.scss']
})
export class ProductsearchComponent implements OnInit {

  constructor(private loading: NgxSpinnerService,private serviceRepository:ServiceRepository) { }

  @ViewChild('searchKey') searchKey; 

  searchProductListTmp:Product[] = [];
  searchProductList:Product[] = [];
  page:number = 1;
  pageSize:number = 10;
  
   collectionSize:number;

  refreshCountries() {
    this.searchProductListTmp = this.searchProductList
      .map((country, i) => ({id: i + 1, ...country}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }



  searchProduct(){
    this.loading.show();
    debugger;
    this.serviceRepository.productSearchCodeOrName(this.searchKey.nativeElement.value,-1,this.warehouseId).subscribe(x=>{
     
     if(this.zeroQuantity)
      x.forEach(element => {
        element.quantity=0;
      });
      this.searchProductList =x;
      this.refreshCountries();
      this.collectionSize = x.length;
      this.loading.hide();
    });
    }

  @Output() setProduct = new EventEmitter<Product>();

  @Input() hideQuantity:boolean = false;
  @Input() zeroQuantity:boolean = false;
  @Input() warehouseId:number = -1;
  @Input() resetList:boolean = false;

 returnProduct(item:Product){
   item.quantity= item.quantity==0 ? 1 : item.quantity;
  this.setProduct.emit(item);
 }
  ngOnInit(): void {
  }

  ngOnChanges() {
if(this.resetList)
this.searchProduct();
    }   

}
