import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceRepository } from '../services/service.repository';
import { AuthCustomer, AuthProduct } from '../services/page.auth.model';
import { Product } from '../services/product.model';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '../services/translate.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../services/auth.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ProductTransaction } from '../services/productTransaction.model';
import { ProductType } from '../services/productType.model';
import { Unit } from '../services/unit.model';
import { MatPaginator } from '@angular/material/paginator';
import { ProductTransactionQuantity } from '../services/productTransactionQuantity.model';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-production-report',
  templateUrl: './production-report.component.html',
  styleUrls: ['./production-report.component.scss']
})

export class ProductionReportComponent implements OnInit {
  authCustomer: AuthCustomer = {};
  dataSource: MatTableDataSource<ProductTransactionQuantity>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild(MatSort) sort: MatSort;
  product: Product = new Product();
  productList: Product[] = [];
  manuList: ProductTransactionQuantity[] = [];
  productListTmp: Product[] = [];
  unitList: Unit[] = [];
  response: number = 0;
  reportTotalQuantity: number = 0;
  faultQuantities: number = 0;
  scrapQuantities: number = 0;
  rate: number = 0;
  productTypeList: ProductType[] = [];
  transactionList: ProductTransaction[] = [];
  selectedProduct: Product;
  @ViewChild('productTransaction', { static: false }) productTransaction: ModalDirective;
  @ViewChild('productionTransaction', { static: false }) productionTransaction: ModalDirective;
  tableColumns: string[] = [];
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  warehouseId: number = 0;
  au: AuthService;
  constructor(private translateService: TranslateService, private serviceRepository: ServiceRepository, private modalService: NgbModal, private spinner: NgxSpinnerService) {
    this.dataSource = new MatTableDataSource();
    this.serviceRepository.getUserPageAuth(this.constructor.name.replace('Component', '')).then(x => {
      this.authCustomer = x;
      this.tableColumns = [];
      Object.keys(this.authCustomer).forEach((key) => {
        if (key.startsWith('table'))
          this.tableColumns.push(key);
      })
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLocaleLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  authProduct: AuthProduct = {};

  getQuantityList() {
    this.reportTotalQuantity = 0;
    let startDate = new Date(this.startDate.year, this.startDate.month - 1, this.startDate.day);
    let endDate = new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day);
    startDate.setMinutes(startDate.getMinutes() - 1);
    endDate.setDate(endDate.getDate() + 1);
    this.serviceRepository.getListManufactureQuantity(startDate, endDate, this.warehouseId).then(x => {
      this.manuList = x;
      this.manuList.forEach(item => {
        this.reportTotalQuantity += item.totalQuantity;
      });
      this.dataSource = new MatTableDataSource(x);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate =
        (data: ProductTransactionQuantity, filter: string) => (data.productCode.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1
          || data.productName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1
          || data.totalQuantity.toLocaleString().indexOf(filter.toLocaleLowerCase()) != -1
        );
      this.paginator._intl.itemsPerPageLabel = this.translateService.getLanguage("Items per page", "tr");
      this.paginator._intl.lastPageLabel = this.translateService.getLanguage(this.paginator._intl.lastPageLabel, "tr");
      this.paginator._intl.previousPageLabel = this.translateService.getLanguage(this.paginator._intl.previousPageLabel, "tr");
      this.paginator._intl.nextPageLabel = this.translateService.getLanguage(this.paginator._intl.nextPageLabel, "tr");
      this.spinner.hide();
    })
  }

  ngOnInit(): void {
    this.startDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() - 1 }; // başlangıç tarihini ayarla
    this.endDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() - 1 }; // bitiş tarihini ayarla
    this.getQuantityList();
    this.dataSource.paginator = this.paginator;
  }

}
