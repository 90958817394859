<div class="" id="page-wrapper">
  <div class="container-fluid">
    <div class="row row-stat">
      <div class="col-sm-12">
            <div class="panel panel-default panel-table">
              <div style="display: contents;" class="table-responsive">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row">
                        <label class="col-sm-3 control-label" for="form-control-2">Başlangıç Tarihi</label>
                        <div class="col-sm-6">
                          <div class="input-group">
                            <input autocomplete="off" [(ngModel)]="startDate" class="form-control" (click)="d.toggle()"
                              placeholder="yyyy-mm-dd" name="dpStart" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                type="button"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="row">
                        <label class="col-sm-3 control-label" for="form-control-2">Bitiş Tarihi</label>
                        <div class="col-sm-6">
                          <div class="input-group">
                            <input autocomplete="off" [(ngModel)]="endDate" class="form-control" (click)="dEnd.toggle()"
                              placeholder="yyyy-mm-dd" name="dpEnd" ngbDatepicker #dEnd="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="dEnd.toggle()"
                                type="button"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="row">
                        <label class="col-sm-3 control-label" for="form-control-2"> Depo</label>
                        <div class="col-sm-6">
                          <select  [(ngModel)]="selectedWarehouseId" (change)="getManufacturePlainList()"  name="selectedWarehouseId" class="form-control">
                            <option [ngValue]="-1">Tümü</option>
                            <option *ngFor="let item of warehouseList" [ngValue]="item.id">{{item.description}}</option>                       
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <button class="btn btn-outline-secondary calendar" (click)="getManufacturePlainList()"
                      type="button">Listele</button>
                    </div>
                  </div>
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:plainNo')">Plan No</th>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:plainDate')">Plan Tarihi</th>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:startDate')">Başlangıç Tarihi</th>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:startedPerson')">Başlatan</th>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:productCode')">Ürün Kodu</th>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:productName')">Ürün Adı</th>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:planedQuantity')">Planlanan Miktar</th>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:manufacturedQuantity')">Üretilen Miktar</th>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:unit')">Birim</th>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:warehouse')">Depo</th>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:prescription')">Reçete</th>
                      <th [hidden]="!au.checkPermission('controls:manufacture:table:status')">Durum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of manufacturePlainList">
                      <td>{{item.id}}</td>
                      <td>{{item.plainDateStr}}</td>
                      <td>{{item.plainStartDateStr}}</td>
                      <td>{{item.startedPerson}}</td>
                      <td>{{item.productCode}}</td>
                      <td>{{item.productName}}</td>
                      <td>{{item.quantity}}</td>
                      <td>{{item.complatedQuantity}}</td>
                      <td>{{item.unitDesc}}</td>
                      <td>{{item.warehouseDesc}}</td>
                      <td><button (click)="showPrescription(item)" class="btn btn-lg btn-outline-primary">Reçete</button>
                     
                    </td>
                      <td><button
                          [hidden]="(item.status=='Üretimde' || item.status=='Tamamlandı' || item.status=='İptal Edildi' || item.status=='Başlanmadı')"
                          (click)="startManufacture(item,false)" class="btn btn-lg btn-outline-primary">Üretime Başla</button>
                        <span [hidden]="(item.status=='Beklemede')">{{item.status}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <ng-template #unavailableModal let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">Reçete Detay</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="panel panel-default">
                      <div class="panel-heading panel-heading-no-divider">{{selectedPlain.productCode}}<span
                          class="panel-subtitle">{{selectedPlain.productName}} ürününü üretmek için {{selectedPlain.warehouseDesc}} deposunda eksik yan
                          ürünler listelenmektedir.</span></div>
                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Ürün Kodu</th>
                              <th>Ürün Adı</th>
                              <th>Eksik Miktar</th>
                              <th>Birim</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of selectedPlain.unavailableProducts">
                              <td>{{item.productCode}}</td>
                              <td>{{item.productName}}</td>
                              <td>{{item.quantity}}</td>
                              <td>{{item.unitDesc}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">

                </div>
              </ng-template>

              <ng-snotify></ng-snotify>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #prescriptionModal let-modal>
      <app-prescription-detail [plainQuantity]="selectedPlain.quantity" [prescriptionId]="selectedPlain.prescriptionId" [prescriptionName]="selectedPlain.productName" [deleteAccess]="false" [addAccess]="false"></app-prescription-detail>
    </ng-template>   