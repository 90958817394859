<ng-template #addProductModal let-modal>
    <app-productsearch   (setProduct)="addDetail($event)"></app-productsearch>
  </ng-template>
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Reçete Detay</h4>
    <button type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="detailModal">
    <form>
      <div class="panel panel-default">
        <div class="panel-heading panel-heading-no-divider"><span class="panel-subtitle">{{prescriptionName}} ürününü üretmek için gerekli yan ürünler listelenmektedir.</span></div>
        <div style="display: flex;" class="col-md-12">
          <div [hidden]="!addAccess" class="col-md-6"><button (click)="addProduct(false)" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-plus"></i> Ürün Ekle</button></div>
          <div style="text-align: right;" class="col-md-6"><button (click)="printPage('detailModal')" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-plus"></i> Yazdır</button></div>
        </div>
        
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Ürün Kodu</th>
                    <th>Ürün Adı</th>
                    <th>Miktar</th>
                    <th>Birim</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of prescriptionDetailList">
                    <td>{{item.productCode}}</td>
                    <td>{{item.productName}}</td>
                    <td>{{item.quantity*plainQuantity}}</td>
                    <td>{{item.unitDesc}}</td>
                      <td [hidden]="!deleteAccess"><button class="btn btn-lg btn-outline-primary" (click)="deleteDetail(item);">Sil</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
    </form>
  </div>
  <div class="modal-footer">

  </div>