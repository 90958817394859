import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerComponent } from './customer/customer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { ManufactureComponent } from './manufacture/manufacture.component';
import { ManufacturecomplateComponent } from './manufacturecomplate/manufacturecomplate.component';
import { OutageComponent } from './outage/outage.component';
import { PlainComponent } from './plain/plain.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { ProductComponent } from './product/product.component';
import { AuthGuardService } from './services/loginguard.service';
import { SharedComponent } from './shared/shared.component';
import { SyncComponent } from './sync/sync.component';
import { UsersComponent } from './users/users.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { WarehousetransferComponent } from './warehousetransfer/warehousetransfer.component';
import { ProductionReportComponent } from './production-report/production-report.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login',component:LoginComponent },
  {
    path: 'product',
    component: SharedComponent,canActivate: [AuthGuardService],
    children: [
      { path: '', component: ProductComponent }
    ]
  },  {
    path: 'invoice',
    component: SharedComponent,
    children: [
      { path: '', component: InvoiceComponent,canActivate: [AuthGuardService] }
    ]
  },  {
    path: 'warehouse',
    component: SharedComponent,
    children: [
      { path: '', component: WarehouseComponent,canActivate: [AuthGuardService] }
    ]
  },  {
    path: 'warehousetransfer',
    component: SharedComponent,
    children: [
      { path: '', component: WarehousetransferComponent,canActivate: [AuthGuardService] }
    ]
  },  {
    path: 'prescription',
    component: SharedComponent,
    children: [
      { path: '', component: PrescriptionComponent,canActivate: [AuthGuardService] }
    ]
  },  {
    path: 'plain',
    component: SharedComponent,
    children: [
      { path: '', component: PlainComponent,canActivate: [AuthGuardService] }
    ]
  },  {
    path: 'manufacture',
    component: SharedComponent,
    children: [
      { path: '', component: ManufactureComponent,canActivate: [AuthGuardService] }
    ]
  }
  ,  {
    path: 'manufacturecomplate',
    component: SharedComponent,
    children: [
      { path: '', component: ManufacturecomplateComponent,canActivate: [AuthGuardService] }
    ]
  }
  ,  {
    path: 'dashboard',
    component: SharedComponent,
    children: [
      { path: '', component: DashboardComponent ,canActivate: [AuthGuardService]}
    ]
  }  ,  {
    path: 'sync',
    component: SharedComponent,
    children: [
      { path: '', component: SyncComponent ,canActivate: [AuthGuardService]}
    ]
  }
  ,  {
    path: 'main',
    component: SharedComponent,
    children: [
      { path: '', component: MainComponent ,canActivate: [AuthGuardService]}
    ]
  },
  {
    path: 'users',
    component: SharedComponent,
    children: [
      { path: '', component: UsersComponent ,canActivate: [AuthGuardService]}
    ]
  },
  {
    path: 'outage',
    component: SharedComponent,
    children: [
      { path: '', component: OutageComponent ,canActivate: [AuthGuardService]}
    ]
  },
  {
    path: 'customer',
    component: SharedComponent,
    children: [
      { path: '', component: CustomerComponent ,canActivate: [AuthGuardService]}
    ]
  },
  {
    path: 'production-report',
    component: SharedComponent,
    children: [
      { path: '', component: ProductionReportComponent ,canActivate: [AuthGuardService]}
    ]
  },
  { path:"**", component: MainComponent,pathMatch: 'full' }
];
 
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
