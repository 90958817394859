import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { NgxSpinnerService } from 'ngx-spinner';
import { Product } from '../services/product.model';
import { ServiceRepository } from '../services/service.repository';
import { Warehouse } from '../services/warehouse.model';

@Component({
  selector: 'app-outage',
  templateUrl: './outage.component.html',
  styleUrls: ['./outage.component.scss']
})
export class OutageComponent implements OnInit {

  constructor(private loading: NgxSpinnerService,private snotifyService: SnotifyService,private serviceRepository:ServiceRepository) { }
  warehouseList:Warehouse[] = [];
  fromWarehouse:Warehouse = {    id:-1,  code:'',  description:'',  createId:1}
  isResetList:boolean=false;
  transferNote:string = '';
  selectProduct(item:Product):void{
    if(this.fromWarehouse.id<=0)
{
  this.snotifyService.error("Lütfen fire yapılacak depoyu seçiniz...");
  return;
}

    if(item.quantity<=0){
      this.snotifyService.error("Lütfen 0 dan büyük bir değer giriniz...");
      return;
    }
    this.loading.show();
item.warehouseId = this.fromWarehouse.id;
this.serviceRepository.outageProduct(item,this.transferNote).then(x=>{
  this.loading.hide();
  this.snotifyService.success("İşleminiz başarı ile tamamlanmıştır...");
})

  }

  ngOnInit(): void {
    this.loading.show();
    this.serviceRepository.getWarehouses().then(x=> {this.warehouseList=x;
      this.loading.hide();
    });
  }

}
