import {Injectable} from "@angular/core"
import { HttpParams } from "@angular/common/http";
import { PrescriptionDetail } from "./prescription.detail.model";
import { Prescription } from "./prescription.model";
import { ProductType } from "./productType.model";
import { RestService } from "./rest.service";
import { Unit } from "./unit.model";
import { Warehouse } from "./warehouse.model";
import { WarehouseQuantity } from "./warehousequantity.model";
import { Manufacture, ReturnManufacture } from "./manufacture.model";
import { ProductTransaction, ProductTransactionList } from "./productTransaction.model";
import { Invoice } from "./invoice.model";
import { InvoiceDetail } from "./invoice.detail.model";
import { WarehouseTransfer } from "./warehousetransfer.model";
import { ManufacturePlain } from "./manufactureplain.model";
import { Login } from "./Login.model";
import { Observable } from "rxjs";
import { StorageMap } from "@ngx-pwa/local-storage";
import { Product } from "./product.model";
import { PlainFuture } from "./plain.future.model";
import { User } from "./user.model";
import { UserAuthorization } from "./userauthorization.model";
import { UserRole } from "./userrole.model";
import { WarehouseOwner } from "./warehouseowner.model";
import { Customer } from "./customer.model";
import { CustomerInvoice } from "./customer.invoice.model";
import { CustomerInvoiceDetail } from "./customer.invoicedetail.model";
import { ProductTransactionQuantity } from "./productTransactionQuantity.model";

@Injectable()
export class ServiceRepository  {
    constructor(private restService:RestService,private storage: StorageMap) {
        
    }
   
    getUserList():Promise<User[]>{
  return this.restService.getUserList().toPromise();
}

getCustomerList():Promise<Customer[]>{
  return this.restService.getCustomerList().toPromise();
}


getCustomerInvoiceList(customerCode:string,startDate:Date,endDate:Date):Promise<CustomerInvoice[]>{
  return this.restService.getCustomerInvoiceList(customerCode,startDate,endDate).toPromise();
}

getCustomerInvoiceDetailList(documentNo:string,customerLogicalRef:number):Promise<CustomerInvoiceDetail[]>{
  return this.restService.getCustomerInvoiceDetailList(documentNo,customerLogicalRef).toPromise();
}

getUserAuthorizationListByUserId(userId:number):Promise<UserAuthorization[]>{
  return this.restService.getUserAuthorizationListByUserId(userId).toPromise();
}
getWarehouseListByUserId(userId:number):Promise<WarehouseOwner[]>{
  return this.restService.getWarehouseListByUserId(userId).toPromise();
}

getUserRoleListByUserId(userId:number):Promise<UserRole[]>{
  return this.restService.getUserRoleListByUserId(userId).toPromise();
}


editOrAddAuthorization(item:UserAuthorization):Promise<UserAuthorization>{
  return this.restService.editOrAddAuthorization(item).toPromise();
}

editOrAddRole(item:UserRole):Promise<UserRole>{
  return this.restService.editOrAddRole(item).toPromise();
}

editOrAddWarehouseOwner(item:WarehouseOwner):Promise<WarehouseOwner>{
  return this.restService.editOrAddWarehouseOwner(item).toPromise();
}

addOrEditUser(item:User):Promise<User>{
  return this.restService.addOrEditUser(item).toPromise();
}

getWarehouses():Promise<Warehouse[]>{
  return this.restService.getWarehouseList().toPromise();
}

addWarehouse(warehouse:Warehouse):Promise<Warehouse>{
return this.restService.addWarehouse(warehouse).toPromise();
}

getProductQuantityList(productId:number):Promise<WarehouseQuantity[]>{
  return this.restService.getProductQuantityList(productId).toPromise();
}

getManufactureListByProductId(startDate:Date,endDate:Date,productId:number):Promise<number>{
  return this.restService.getManufactureListByProductId(startDate,endDate,productId).toPromise();
}
getListManufactureQuantity(startDate:Date,endDate:Date, warehouseId:number):Promise<ProductTransactionQuantity[]>{
  return this.restService.getListManufactureQuantity(startDate,endDate,warehouseId).toPromise();
}
getListFaultyControlByProductId(startDate:Date,endDate:Date,productCode:string):Promise<number>{
  return this.restService.getListFaultyControlByProductId(startDate,endDate,productCode).toPromise();
}
getListScrapByProductId(startDate:Date,endDate:Date,productCode:string):Promise<number>{
  return this.restService.getListScrapByProductId(startDate,endDate,productCode).toPromise();
}

getWarehouseQuantityListById(id:number):Promise<WarehouseQuantity[]>{
  return this.restService.getWarehouseQuantityListById(id).toPromise();
}

getProducts():Promise<Product[]>{
  return this.storage.get("token").toPromise().then(x=>{
    return this.restService.getProductList(x).toPromise();});
}
getManufactures(startDate:Date,endDate:Date,warehouseId:number):Promise<ReturnManufacture>{
return this.restService.getManufactureList(startDate,endDate,warehouseId).toPromise();
}
getPrescriptions():Promise<Prescription[]>{
   return this.restService.getPrescriptionList().toPromise();
}

getManuQuantityByProductId(productId:number,startDate:Date):Promise<number>{
   return this.restService.getPrescriptionListByProductId(productId,startDate).toPromise();
}
getPrescriptionActiveList():Promise<Prescription[]>{
  return this.restService.getPrescriptionActiveList().toPromise();
}

getPrescriptionListByWarehouseId(id:number):Promise<Prescription[]>{
  return this.restService.getPrescriptionListByWarehouseId(id).toPromise();
}


getProductTypes():Promise<ProductType[]>{
   return this.restService.getProductTypeList().toPromise();
}
getProductUnits():Promise<Unit[]>{
    return this.restService.getProductUnitList().toPromise();
}

addProduct(product:Product):Promise<Product>{
    return this.restService.addProduct(product).toPromise();
  }

  addPrescription(prescription:Prescription):Promise<Prescription>{
    return this.restService.addPrescription(prescription).toPromise();
  }

  productSearchCodeOrName(key:string,type:number,warehouseId:number):Observable<Product[]>{
    let param = new HttpParams().set("key",key).set("type",type.toString());
    return this.restService.productSearchCodeOrName(key,type,warehouseId);
  }

  deletePrescriptionDetail(item:PrescriptionDetail):Promise<any>{
   return this.restService.deletePrescriptionDetail(item).toPromise();
  }

  getPrescriptionDetailListById(id:number):Promise<PrescriptionDetail[]>{
    return this.restService.getPrescriptionDetailListById(id).toPromise();
  }
  getPrescriptionDetailListByWarehouseId(id:number, warehouseId:number):Promise<PrescriptionDetail[]>{
    return this.restService.getPrescriptionDetailListByWarehouseId(id, warehouseId).toPromise();
  }

  getPrescriptionIdByProductId(productId:number,startDate:Date):Promise<number>{
    return this.restService.getPrescriptionIdByProductId(productId,startDate).toPromise();
  }

  getProductTransactionListByDateAndProductId(startDate:Date,endDate:Date,productId:number):Promise<ProductTransaction[]>{
    return this.restService.getProductTransactionListByDateAndProductId(startDate,endDate,productId).toPromise();
  }

  updateAppearInReport(id:number):Promise<boolean>{
    return this.restService.UpdateAppearInReport(id).toPromise();
  }

  completeManufacture(manufacture:Manufacture):Promise<Manufacture>{
    return this.restService.completeManufacture(manufacture).toPromise();
  }

  outageProduct(product:Product,transferNote:string):Promise<Product>{
    return this.restService.outageProduct(product,transferNote).toPromise();
  }

  addPrescriptionDetail(prescriptionDetail:PrescriptionDetail):Promise<PrescriptionDetail>{
    return this.restService.addPrescriptionDetail(prescriptionDetail).toPromise();
  }

  addManufacturePlain(manufacturePlain:ManufacturePlain):Promise<ManufacturePlain>{
    return this.restService.addManufacturePlain(manufacturePlain).toPromise();
  }

  removeManufacturePlain(manufacturePlain:ManufacturePlain):Promise<ManufacturePlain>{
    return this.restService.removeManufacturePlain(manufacturePlain).toPromise();
  }

  deletePrescription(prescription:Prescription):Promise<Prescription>{
    return this.restService.deletePrescription(prescription).toPromise();
  }

  updateProductCritical(product:Product):Promise<Product>{
    return this.restService.updateProductCritical(product).toPromise();
  }

  updatePrescriptionDetail(prescriptionDetail:PrescriptionDetail):Promise<PrescriptionDetail>{
    return this.restService.updatePrescriptionDetail(prescriptionDetail).toPromise();
  }

  startManufacturePlain(manufacture:Manufacture):Promise<Manufacture>{
    return this.restService.startManufacturePlain(manufacture).toPromise();
  }

  getInvoiceList():Promise<Invoice[]>{
    return this.restService.getInvoiceList().toPromise();
  }

  getInvoiceDetailList(invoiceId:number):Promise<InvoiceDetail[]>{
    return this.restService.getInvoiceDetailList(invoiceId).toPromise();
  }

  addInvoice(item:Invoice):Promise<Invoice>{
    return this.restService.addInvoice(item).toPromise()
  }

  syncProduct():Promise<Invoice>{
    return this.restService.syncProduct().toPromise()
  }
  
  getTransferList(startDate:Date,endDate:Date,warehouseId:number):Promise<ProductTransactionList[]>{
    return this.restService.getTransferList(startDate,endDate,warehouseId).toPromise();
  }

  getManufacturePlainListByDate(startDate:Date,endDate:Date,warehouseId:number):Promise<ManufacturePlain[]>{
   return this.restService.getManufacturePlainListByDate(startDate,endDate,warehouseId).toPromise();
  }

  getManufacturePlainListByYearAndMonth(year:number,month:number):Promise<number[]>{
    return this.restService.getManufacturePlainListByYearAndMonth(year,month).toPromise();
   }

   getManufactureFuturePlainList(startDate:Date,endDate:Date):Promise<PlainFuture[]>{
    return this.restService.getManufactureFuturePlainList(startDate,endDate).toPromise();
   }

  addWarehouseTransfer(list:WarehouseTransfer[],transferNote:string):Promise<WarehouseTransfer[]>{
    return this.restService.addWarehouseTransfer(list,transferNote).toPromise();
  }

  getUserPageAuth(pageName:string):Promise<any>{
    return this.restService.getUserPageAuth(pageName).toPromise();
  }


}