import { Component,  OnInit, ViewChild } from '@angular/core';
import { Product } from '../services/product.model';
import { ProductType } from '../services/productType.model';
import { Unit } from '../services/unit.model';
import { ProductTransaction } from '../services/productTransaction.model';
import { WarehouseQuantity } from '../services/warehousequantity.model';
import { ServiceRepository } from '../services/service.repository';
import { SnotifyService } from 'ng-snotify';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthService } from '../services/auth.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '../services/translate.service';
import { AuthProduct } from '../services/page.auth.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Manufacture } from '../services/manufacture.model';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
})

export class ProductComponent implements  OnInit {
  dataSource: MatTableDataSource<Product>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild(MatSort) sort: MatSort;
  product: Product = new Product();
  productList: Product[] = [];
  productListTmp: Product[] = [];
  unitList: Unit[] = [];
  response: number = 0;
  faultQuantities: number = 0;
  scrapQuantities: number = 0;
  rate: number = 0;
  productTypeList: ProductType[] = [];
  transactionList:ProductTransaction[] = [];
  selectedProduct:Product;
  @ViewChild('productTransaction', { static: false }) productTransaction: ModalDirective;
  @ViewChild('productionTransaction', { static: false }) productionTransaction: ModalDirective;
  tableColumns:string[] = [];
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
au:AuthService;
  constructor(private loading: NgxSpinnerService,private calendar: NgbCalendar,private translateService:TranslateService,private confirmationDialogService: ConfirmationDialogService,private snotifyService: SnotifyService,private serviceRepository:ServiceRepository,private modalService:NgbModal,private authService:AuthService) {
    this.au=authService; 
    this.dataSource = new MatTableDataSource();
    this.serviceRepository.getUserPageAuth(this.constructor.name.replace('Component','')).then(x=>{
      this.authProduct = x;  
        this.tableColumns = [];
      Object.keys(this.authProduct).forEach((key) => {
        if(key.startsWith('table'))
        this.tableColumns.push(key);
      })
    });
  }


  getTransactions():void{
    this.loading.show();
    let startDate = new Date(this.startDate.year,this.startDate.month-1,this.startDate.day);
    let endDate = new Date(this.endDate.year,this.endDate.month-1,this.endDate.day);
    let productId = this.selectedProduct.id;
    this.serviceRepository.getProductTransactionListByDateAndProductId(startDate,endDate,productId).then(x=>{
      this.transactionList = x;
      this.loading.hide();
    });
  }

  changeAppear(id:number):void{
    this.loading.show();
    this.serviceRepository.updateAppearInReport(id).then(x=>{
      this.loading.hide();
    });
  }

  updateCriticalLevel(item:Product):void{
    this.loading.show();
    this.serviceRepository.updateProductCritical(item).then(x=>{
      this.getProductList();
      this.loading.hide();
      this.snotifyService.success("İşlem başarılı...");
    });
  }


 authProduct:AuthProduct = {};
  showTransactionModal(item:Product):void{
    this.selectedProduct = item;
    let date:Date = new Date();
    date.setDate(date.getDate()-7);
    this.startDate = {year:date.getFullYear(),day:date.getDate(),month:date.getMonth()+1};
    this.endDate = this.calendar.getToday();

    this.getTransactions();
    this.modalService.open(this.productTransaction, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
/*
    this.selectedProduct = item;
this.serviceRepository.getProductTransactionListByProductId(item.id).then(x=>{
  this.transactionList = x;
this.modalService.open(this.productTransaction, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
})*/
  }

  
  showProductionModal(item:Product):void{
    this.selectedProduct = item;
    let date:Date = new Date();
    date.setDate(date.getDate()-7);
    this.startDate = {year:date.getFullYear(),day:date.getDate(),month:date.getMonth()+1};
    this.endDate = this.calendar.getToday();

    this.getTransactions();
    this.modalService.open(this.productionTransaction, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
/*
    this.selectedProduct = item;
this.serviceRepository.getProductTransactionListByProductId(item.id).then(x=>{
  this.transactionList = x;
this.modalService.open(this.productTransaction, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
})*/
  }
  ngOnInit(): void {
    this.getProductList();
    // this.confirmationDialogService.confirm("Uyarı!!!...","Eminmisiniz?","Evet","İptal","lg")
    // .then((confirmed) => console.log('User confirmed:', confirmed))
    // .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  getProductQuantities():void{
    this.getManuByProductId();
    this.getListFaultyControlByProductId();
    this.getListScrapByProductId();
  }

  getManuByProductId():void{
    let startDate = new Date(this.startDate.year,this.startDate.month-1,this.startDate.day);
    let endDate = new Date(this.endDate.year,this.endDate.month-1,this.endDate.day);
    this.serviceRepository.getManufactureListByProductId(startDate,endDate,this.selectedProduct.id).then(x=>{
      this.response = x;
    });
  }
  
  getListFaultyControlByProductId():void{
    let startDate = new Date(this.startDate.year,this.startDate.month-1,this.startDate.day);
    let endDate = new Date(this.endDate.year,this.endDate.month-1,this.endDate.day);
    this.serviceRepository.getListFaultyControlByProductId(startDate,endDate,this.selectedProduct.code).then(x=>{
      this.faultQuantities = x;
    });
  }
  getListScrapByProductId():void{
    let startDate = new Date(this.startDate.year,this.startDate.month-1,this.startDate.day);
    let endDate = new Date(this.endDate.year,this.endDate.month-1,this.endDate.day);
    this.serviceRepository.getListScrapByProductId(startDate,endDate,this.selectedProduct.code).then(x=>{
      this.scrapQuantities = x;
      this.rate = ((this.faultQuantities + this.scrapQuantities)/this.response)*100;
    });
  }

  getProductList():void{
    this.loading.show();
    this.serviceRepository.getProducts().then(x=>{
      this.productList = x;
      this.dataSource =new MatTableDataSource(x);
      this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = 
  (data: Product, filter: string) => (data.code.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!=-1 || data.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!=-1);
      this.paginator._intl.itemsPerPageLabel = this.translateService.getLanguage("Items per page","tr");
      this.paginator._intl.lastPageLabel = this.translateService.getLanguage(this.paginator._intl.lastPageLabel,"tr");
      this.paginator._intl.previousPageLabel = this.translateService.getLanguage(this.paginator._intl.previousPageLabel,"tr");
      this.paginator._intl.nextPageLabel = this.translateService.getLanguage(this.paginator._intl.nextPageLabel,"tr");
      // this.refreshCountries();
      // this.collectionSize = x.length;
      // this.serviceRepository.getProductTypes().then(x=>this.productTypeList=x);
      // this.serviceRepository.getProductUnits().then(x=>this.unitList = x);
      this.loading.hide();
    });
  }

  warehouseList:WarehouseQuantity[] = [];
  
  getQuantityList(productId:number){
    this.serviceRepository.getProductQuantityList(productId).then(x=>{
this.warehouseList = x;
    })
  }

  newRecord(isAdd:boolean):void{
    this.isAdd=isAdd;
    if(isAdd){
    this.serviceRepository.getProductTypes().then(x=>this.productTypeList=x);
    this.serviceRepository.getProductUnits().then(x=>this.unitList = x);}
  }


  isAdd = false;
  saveProduct(product:Product) {
     product.createId=1;
     this.loading.show();
this.serviceRepository.addProduct(product).then(x=>{
  this.getProductList();
  this.isAdd = false;
  this.loading.hide();
  this.snotifyService.success("İşlem başarılı...");
  this.product = new Product();
});

  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLocaleLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }


}