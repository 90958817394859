import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

const I18N_VALUES = {
    'tr': {
      weekdays: ['Pzt', 'Sa', 'Çrş', 'Per', 'Cum', 'Cts', 'Pz'],
      months: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
      'Items per page':'Sayfa Başı Kayıt',
      'Previous page':'Önceki sayfa',
      'Next page':'Sonraki sayfa',
      'Last page':'Son sayfa'
    },
    'en':{
        'Items per page':'Items per page' ,
        'Previous page':'Previous page',
        'Next page':'Next page',
        'Last page':'Last page'
    }
    // other languages you would support
  };
  const now = new Date();
  @Injectable()
  export class I18n {
    language = 'tr';
  }
  
  @Injectable()
  export class CustomDatepickerI18n extends NgbDatepickerI18n {
  
    constructor(private _i18n: I18n) {
      super();
    }
  
    getWeekdayShortName(weekday: number): string {
      return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    }
    getMonthShortName(month: number): string {
      return I18N_VALUES[this._i18n.language].months[month - 1];
    }
    getMonthFullName(month: number): string {
      return this.getMonthShortName(month);
    }
  
    getDayAriaLabel(date: NgbDateStruct): string {
      return `${date.day}-${date.month}-${date.year}`;
    }
  }

  @Injectable()
  export class TranslateService{
getLanguage(value,lang):string{
    return I18N_VALUES[lang][value];
}
  }
