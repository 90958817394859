<div class="" id="page-wrapper">
    <div class="container-fluid">
      <div class="row row-stat">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <label class="col-sm-3 control-label" for="form-control-2">Başlangıç Tarihi</label>
                    <div class="col-sm-6">
                      <div class="input-group">
                        <input autocomplete="off" [(ngModel)]="startDate" class="form-control" (click)="d.toggle()"
                          placeholder="yyyy-mm-dd" name="dpStart" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                            type="button"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <label class="col-sm-3 control-label" for="form-control-2">Bitiş Tarihi</label>
                    <div class="col-sm-6">
                      <div class="input-group">
                        <input autocomplete="off" [(ngModel)]="endDate" class="form-control" (click)="dEnd.toggle()"
                          placeholder="yyyy-mm-dd" name="dpEnd" ngbDatepicker #dEnd="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="dEnd.toggle()"
                            type="button"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <label class="col-sm-3 control-label" for="form-control-2"> Depo</label>
                    <div class="col-sm-6">
                      <select  [(ngModel)]="selectedWarehouseId" (change)="getManufactures()"  name="selectedWarehouseId" class="form-control">
                        <option [ngValue]="-1">Tümü</option>
                        <option *ngFor="let item of warehouseList" [ngValue]="item.id">{{item.description}}</option>                       
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <button class="btn btn-outline-secondary calendar" 
                  type="button" (click)="getManufactures()">Listele</button>
                </div>
              </div>
            </div>
            <div class="col-md-12">

      <table id="manufactureTable" class="table table-striped" cellspacing="0">
        <thead>
          <tr>
            <th [hidden]="!au.checkPermission('controls:manufacturecomplate:table:plainNo')">Plan No</th>
            <th [hidden]="!au.checkPermission('controls:manufacturecomplate:table:plainDate')">Plan Tarihi</th>
            <th [hidden]="!au.checkPermission('controls:manufacturecomplate:table:productName')">Ürün Adı</th>
            <th [hidden]="!au.checkPermission('controls:manufacturecomplate:table:planedQuantity')">Planlanan Miktar</th>
            <th [hidden]="!au.checkPermission('controls:manufacturecomplate:table:manufacturedQuantity')">Üretilen Miktar</th>
            <th [hidden]="!au.checkPermission('controls:manufacturecomplate:table:remaininQuantity')">Kalan Miktar</th>
            <th [hidden]="!au.checkPermission('controls:manufacturecomplate:table:complate')">Tamamla</th>
          </tr>
        </thead>
        <tbody *ngFor="let manufacture of manufactureList.notCompleted" >
          <tr [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
            <td>{{manufacture.manufacturePlainId}}</td>
            <td>{{manufacture.plainDate |  date:'dd.MM.yyyy'}}</td>
            <td>{{manufacture.productName}}</td>
            <td><input type="number" [(ngModel)]="manufacture.quantity" disabled name="quantity"></td>
            <td><input type="number" [(ngModel)]="manufacture.completedQuantity" disabled name="completedQuantity"></td>
            <td><input type="number" [(ngModel)]="manufacture.quantity-manufacture.completedQuantity" disabled name="quantityCompletedQuantity"></td>
            <td><label class="custom-control custom-control-success custom-checkbox">
              <input [disabled]="manufacture.quantity<=manufacture.completedQuantity" class="custom-control-input" type="checkbox" [(ngModel)]="manufacture.isAllComplete" (change)="manufacture.completeQuantity= manufacture.isAllComplete ? manufacture.quantity-manufacture.completedQuantity : 0" name="completeQuantity">
              <span class="custom-control-indicator"></span>
              <span class="custom-control-label">Tümünü Tamamla </span>
            </label>
            <input [disabled]="manufacture.quantity<=manufacture.completedQuantity" type="number" [(ngModel)]="manufacture.completeQuantity" (ngModelChange)="manufacture.isAllComplete=manufacture.completedQuantity+manufacture.completeQuantity==manufacture.quantity" name="isAllComplete">
            <button *ngIf="manufacture.quantity>manufacture.completedQuantity" class="btn btn-outline-secondary calendar" (click)="complete(manufacture,false)"  type="button">Tamamla</button>
          </td>   
          </tr>
          <tr [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
            <td style="border-top: 0;" colspan="2">
              <table id="information">
                <thead [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
                  <tr>
                    <td style="border-top: 0;">Başlatan</td>
                    <td style="border-top: 0;">Başlatma Tarihi</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="border-top: 0;">{{manufacture.startedUserName}}</td>
                    <td style="border-top: 0;">{{manufacture.startDate |  date:'dd.MM.yyyy H:mm'}}</td>
                  </tr>
                </tbody>
              </table> 
            </td>
            <td style="border-top: 0;" colspan="5" >
            <table id="information" >
              <thead [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
                <tr>
                  <td style="border-top: 0;">Tamamlayan</td>
                  <td style="border-top: 0;">Tamamlanma Tarihi</td>
                  <td style="border-top: 0;">Tamamlanan Miktar</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let transaction of manufacture.productTransactions">
                  <td style="border-top: 0;">{{transaction.createUserName}}</td>
                  <td style="border-top: 0;">{{transaction.createDate |  date:'dd.MM.yyyy H:mm'}}</td>
                  <td style="border-top: 0;">{{transaction.quantity}}</td>
                </tr>
              </tbody>
            </table> 
          </td>
          </tr>
         
         
       
        </tbody>
        <tbody *ngFor="let manufacture of manufactureList.completed" >
          <tr [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
            <td>{{manufacture.manufacturePlainId}}</td>
            <td>{{manufacture.plainDate |  date:'dd.MM.yyyy'}}</td>
            <td>{{manufacture.productName}}</td>
            <td><input type="number" [(ngModel)]="manufacture.quantity" disabled name="quantity"></td>
            <td><input type="number" [(ngModel)]="manufacture.completedQuantity" disabled name="completedQuantity"></td>
            <td><input type="number" [(ngModel)]="manufacture.quantity-manufacture.completedQuantity" disabled name="quantityCompletedQuantity"></td>
            <td><label class="custom-control custom-control-success custom-checkbox">
              <input [disabled]="manufacture.quantity<=manufacture.completedQuantity" class="custom-control-input" type="checkbox" [(ngModel)]="manufacture.isAllComplete" (change)="manufacture.completeQuantity= manufacture.isAllComplete ? manufacture.quantity-manufacture.completedQuantity : 0" name="completeQuantity">
              <span class="custom-control-indicator"></span>
              <span class="custom-control-label">Tümünü Tamamla </span>
            </label>
            <input [disabled]="manufacture.quantity<=manufacture.completedQuantity" type="number" [(ngModel)]="manufacture.completeQuantity" (ngModelChange)="manufacture.isAllComplete=manufacture.completedQuantity+manufacture.completeQuantity==manufacture.quantity" name="isAllComplete">
            <button *ngIf="manufacture.quantity>manufacture.completedQuantity" class="btn btn-outline-secondary calendar" (click)="complete(manufacture,false)"  type="button">Tamamla</button>
          </td>   
          </tr>
          <tr [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
            <td style="border-top: 0;" colspan="2">
              <table id="information">
                <thead [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
                  <tr>
                    <td style="border-top: 0;">Başlatan</td>
                    <td style="border-top: 0;">Başlatma Tarihi</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="border-top: 0;">{{manufacture.startedUserName}}</td>
                    <td style="border-top: 0;">{{manufacture.startDate |  date:'dd.MM.yyyy H:mm'}}</td>
                  </tr>
                </tbody>
              </table> 
            </td>
            <td style="border-top: 0;" colspan="5" >
            <table id="information" >
              <thead [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
                <tr>
                  <td style="border-top: 0;">Tamamlayan</td>
                  <td style="border-top: 0;">Tamamlanma Tarihi</td>
                  <td style="border-top: 0;">Tamamlanan Miktar</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let transaction of manufacture.productTransactions">
                  <td style="border-top: 0;">{{transaction.createUserName}}</td>
                  <td style="border-top: 0;">{{transaction.createDate |  date:'dd.MM.yyyy H:mm'}}</td>
                  <td style="border-top: 0;">{{transaction.quantity}}</td>
                </tr>
              </tbody>
            </table> 
          </td>
          </tr>
         
         
       
        </tbody>
        <!-- <tbody *ngFor="let manufacture of manufactureList.completed" >
          <tr [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
            <td>{{manufacture.manufacturePlainId}}</td>
            <td>{{manufacture.plainDate |  date:'dd.MM.yyyy'}}</td>
            <td>{{manufacture.productName}}</td>
            <td><input type="number" [(ngModel)]="manufacture.quantity" disabled name="quantity"></td>
            <td><input type="number" [(ngModel)]="manufacture.completedQuantity" disabled name="completedQuantity"></td>
            <td><input type="number" [(ngModel)]="manufacture.quantity-manufacture.completedQuantity" disabled name="quantityCompletedQuantity"></td>
            <td><label class="custom-control custom-control-success custom-checkbox">
              <input [disabled]="manufacture.quantity<=manufacture.completedQuantity" class="custom-control-input" type="checkbox" [(ngModel)]="manufacture.isAllComplete" (change)="manufacture.completeQuantity= manufacture.isAllComplete ? manufacture.quantity-manufacture.completedQuantity : 0" name="completeQuantity">
              <span class="custom-control-indicator"></span>
              <span class="custom-control-label">Tümünü Tamamla </span>
            </label>
            <input [disabled]="manufacture.quantity<=manufacture.completedQuantity" type="number" [(ngModel)]="manufacture.completeQuantity" (ngModelChange)="manufacture.isAllComplete=manufacture.completedQuantity+manufacture.completeQuantity==manufacture.quantity" name="isAllComplete">
            <button *ngIf="manufacture.quantity>manufacture.completedQuantity" class="btn btn-outline-secondary calendar" (click)="complete(manufacture,false)"  type="button">Tamamla</button>
          </td>   
          </tr>
          <tr [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
            <td style="border-top: 0;" colspan="2">
              <table id="information">
                <thead [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
                  <tr>
                    <td style="border-top: 0;">Başlatan</td>
                    <td style="border-top: 0;">Başlatma Tarihi</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="border-top: 0;">{{manufacture.startedUserName}}</td>
                    <td style="border-top: 0;">{{manufacture.startDate |  date:'dd.MM.yyyy H:mm'}}</td>
                  </tr>
                </tbody>
              </table> 
            </td>
            <td style="border-top: 0;" colspan="5" >
            <table id="information" >
              <thead [ngClass]="manufacture.completedQuantity>=manufacture.quantity ? 'succsessManufacture' : 'unSuccsessManufacture'">
                <tr>
                  <td style="border-top: 0;">Tamamlayan</td>
                  <td style="border-top: 0;">Tamamlanma Tarihi</td>
                  <td style="border-top: 0;">Tamamlanan Miktar</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let transaction of manufacture.productTransactions">
                  <td style="border-top: 0;">{{transaction.createUserName}}</td>
                  <td style="border-top: 0;">{{transaction.createDate |  date:'dd.MM.yyyy H:mm'}}</td>
                  <td style="border-top: 0;">{{transaction.quantity}}</td>
                </tr>
              </tbody>
            </table> 
          </td>
          </tr>
         
         
       
        </tbody> -->
      </table>




      <ng-snotify></ng-snotify>
</div></div></div></div></div></div>