<div class="" id="page-wrapper">
    <div class="container-fluid">
      <div class="row row-stat">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-md-12">
          <div class="panel panel-default panel-table">
            <div class="panel-heading panel-heading-no-divider">Üretim Reçeteleri<span class="panel-subtitle">Ürünlere ait üretim reçeteleri listelenmektedir.</span>
             <div [hidden]="!authPage.admin && !authPage.btnNewItem">
              <button [hidden]="isAdd" (click)="newRecord()" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-plus"></i> Yeni Ekle</button>
              <button [hidden]="!isAdd" (click)="isAdd=false" class="btn btn-space btn-danger"><i class="icon mdi mdi-close-circle"></i> Vazgeç</button>
             </div>
                  </div>
            <ng-template #addProductModal let-modal>
              <app-productsearch (setProduct)="addDetail($event)"></app-productsearch>
            </ng-template>
  
            <ng-template #selectProductModal let-modal>
              <app-productsearch [hideQuantity]="true" (setProduct)="selectProduct($event)"></app-productsearch>
            </ng-template>
            
            <ng-template #detailModal let-modal>
              <app-prescription-detail  [prescriptionId]="selectedprescription.id" [prescriptionName]="selectedprescription.productName" [deleteAccess]="true" [addAccess]="true"></app-prescription-detail>
            </ng-template>                  
                <div [hidden]="isAdd" class="table-responsive">


                  <mat-form-field>
                    <input matInput (keyup)="applyFilter($event.target.value)" class="form-control ng-valid ng-dirty ng-touched" type="text" placeholder="Arama..."/>
                  </mat-form-field>
                    
                    <div class="table table-striped">
                    
                      <mat-table [dataSource]="dataSource">
                    
                        <!-- ID Column -->
                        <ng-container  matColumnDef="tableId">
                          <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
                          <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
                        </ng-container>
                    
                        <!-- Progress Column -->
                        <ng-container matColumnDef="tableProductCode">
                          <mat-header-cell *matHeaderCellDef> Ürün Kodu </mat-header-cell>
                          <mat-cell *matCellDef="let row"> {{row.productCode}} </mat-cell>
                        </ng-container>
                    
                        <!-- Name Column -->
                        <ng-container matColumnDef="tableProductName">
                          <mat-header-cell *matHeaderCellDef > Ürün Adı </mat-header-cell>
                          <mat-cell *matCellDef="let row"> {{row.productName}} </mat-cell>
                        </ng-container>
                    
                        <!-- Color Column -->
                        <ng-container matColumnDef="tableQuantity">
                          <mat-header-cell *matHeaderCellDef > Adet </mat-header-cell>
                          <mat-cell *matCellDef="let row"> {{row.quantity}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="tableUnit">
                          <mat-header-cell *matHeaderCellDef > Birim </mat-header-cell>
                          <mat-cell *matCellDef="let row"> {{row.unit}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="tableAddUser">
                          <mat-header-cell *matHeaderCellDef > Reçete Ekleyen </mat-header-cell>
                          <mat-cell *matCellDef="let row"> {{row.createUserName}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="tableWarehouseName">
                          <mat-header-cell *matHeaderCellDef > Üretim Hattı </mat-header-cell>
                          <mat-cell *matCellDef="let row"> {{row.warehouseName}} </mat-cell>
                        </ng-container>
                       
                        <ng-container matColumnDef="tableDetail">
                          <mat-header-cell *matHeaderCellDef> Detay </mat-header-cell>
                          <mat-cell *matCellDef="let row"> 
                            <button class="btn btn-lg btn-primary btn-sm btn-block" (click)="showDetails(row)">Detay</button>
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="tableDelete">
                          <mat-header-cell *matHeaderCellDef> Sil </mat-header-cell>
                          <mat-cell *matCellDef="let row"> 
                            <button class="btn btn-lg btn-primary btn-sm btn-block" (click)="deletePrescription(row,false)">Sil</button>
                          </mat-cell>
                        </ng-container>
                    
                        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: tableColumns;">
                        </mat-row>
                      </mat-table>
                    
                      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>
                </div>
  
                 <div [hidden]="!isAdd" class="panel-body">
                    <div class="xs-mt-10 xs-mb-10  ">
                      <div class="row">
                        <div class="col-md-8">
                          <form class="form-horizontal">
                            <!-- <div class="form-group">
                              <div class="row">
                                <label class="col-sm-3 control-label" for="form-control-2">Reçete Adı</label>
                                <div class="col-sm-9">
                                  <input [(ngModel)]="selectedprescription.description" name="description" class="form-control input-pill" type="text">
                                  </div>
                              </div>
                            </div> -->
                            <div class="form-group">
                              <div class="row">
                                <label class="col-sm-3 control-label" for="form-control-2">Ana Ürün Kodu</label>
                                <div class="col-sm-6">
                                  <input [(ngModel)]="selectedprescription.productCode" readonly name="code" class="form-control input-pill" type="text">
                                  </div>
                                <div class="col-sm-3">
                                  <button (click)="selectProductShow()" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-check"></i> Ürün Seç</button>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                  <label class="col-sm-3 control-label" for="form-control-2">Ana Ürün Adı</label>
                                  <div class="col-sm-9">
                                    <input [(ngModel)]="selectedprescription.productName" readonly name="name" class="form-control input-pill" type="text">
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="row">
                                  <label class="col-sm-3 control-label" for="form-control-2">Ana Ürün Adedi</label>
                                  <div class="col-sm-2">
                                    <input [(ngModel)]="selectedprescription.quantity" readonly name="quantity" class="form-control input-pill" type="text">
                                  </div>
                                  <div class="col-sm-2">
                                    <input [(ngModel)]="selectedprescription.unit" readonly name="unit" class="form-control input-pill" type="text">
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="row">
                                  <label class="col-sm-3 control-label" for="form-control-2">Depo</label>
                                  <div class="col-sm-6">
                                    <select  [(ngModel)]="selectedprescription.warehouseId" name="warehouseId" class="form-control">
                                      <option *ngFor="let item of warehouseList" [ngValue]="item.id">{{item.description}}</option>                       
                                    </select>
                                    </div>
                                </div>
                              </div>
                              <button [hidden]="selectedprescription.productId<=0" (click)="addProduct(true)" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-check"></i> Yarı Mamul Ekle</button>
                              <button [hidden]="selectedprescription.productId<=0 || (selectedprescription.productId>0 && selectedprescription.prescriptionDetails.length<=0)" (click)="addPrescription()" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-check"></i> Kaydet</button> 
     
                              <div [hidden]="selectedprescription.productId<=0 || (selectedprescription.productId>0 && selectedprescription.prescriptionDetails.length<=0)" class="panel panel-default">                           
                                <div class="panel-heading panel-heading-no-divider"><span class="panel-subtitle">{{selectedprescription.productName}} ürününü üretebilmek için gerekli ürünlerin listesi</span></div>
                                    <div class="table-responsive">
                                      <table class="table table-striped">
                                        <thead>
                                          <tr>
                                            <th>Ürün Kodu</th>
                                            <th>Ürün Adı</th>
                                            <th>Adet</th>
                                            <th>Birim</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let item of selectedprescription.prescriptionDetails">
                                            <td>{{item.productCode}}</td>
                                            <td>{{item.productName}}</td>
                                            <td>{{item.quantity}}</td>
                                            <td>{{item.unitDesc}}</td>
                                              <td><button class="btn btn-lg btn-outline-primary" (click)="deleteDetail(item);">Sil</button></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
  
  
  
                          </form>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <ng-snotify></ng-snotify>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  