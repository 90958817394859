import { Injectable } from "@angular/core";
import { BehaviorSubject} from "rxjs";
import { Login } from "./Login.model";
import { RestService } from "./rest.service";
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenModel } from "./token.model";
import { Router } from "@angular/router";
import { ReturnLogin } from "./returnlogin";

@Injectable()
export class  AuthService {
    userToken:TokenModel;
    constructor(private restService:RestService,private router: Router) {
        
    }

getNameSurname():string{
    return localStorage.getItem('nameSurname');
}

getUserId():number{
    let localToken = localStorage.getItem('token');
    const decodedToken = this.helper.decodeToken(localToken);
    return parseInt(decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]);
}

    helper = new JwtHelperService();
checkPermission(item):boolean{
    let localToken = localStorage.getItem('token');
    const decodedToken = this.helper.decodeToken(localToken);
    const roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    return (localStorage.getItem('permissionItems').includes("|all|") || localStorage.getItem('permissionItems').includes("|"+item+"|"));
}
firstPermission():string{
    let localToken = localStorage.getItem('token');
    const decodedToken = this.helper.decodeToken(localToken);
    const roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    return localStorage.getItem('permissionItems').split('|')[1].replace('page:','/');
}

    loggedIn: boolean=false;
    userId:number;

    //Tüm componentlere ben login oldum diyebilmek için.. İhityaç anında kullanılabilir.
    loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);

    results: ReturnLogin = new ReturnLogin();
    

    loginOl(form: Login):Promise<ReturnLogin> {
     return   this.restService.auth(form).then(
            data => {
                if (data.accessToken) {
                    localStorage.setItem('token', data.accessToken);
                    const decodedToken = this.helper.decodeToken(data.accessToken);
                    this.userToken = new TokenModel();
                    let refreshToken = localStorage.getItem('refreshToken');
                    this.userToken.nameSurname = data.nameSurname;;
                    this.userToken.userName = decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
                    this.userToken.userId = decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
                    this.userToken.refreshToken = refreshToken;

                    localStorage.setItem('permissionItems', data.permissionItems);
                    localStorage.setItem('refreshToken', data.refreshToken);
                    localStorage.setItem('nameSurname', data.nameSurname);
                    this.loggedIn$.next(true);
                    this.loggedIn = true;
                    this.results.success = true;
                    return this.results;
                } else {
                    this.results.success = false;
                    return this.results;
                }

            },
            error=>{
            this.results.success = false;
            this.results.message = error;
            return this.results;
         },
         
        );
    }

    logout(){
        localStorage.clear();
        this.router.navigate(['login']);
    }

    main(){
        this.router.navigate(['main']);
    }


    refreshToken(token: TokenModel):Promise<boolean> {
        return   this.restService.refreshToken(token).then(
               data => {
                   if (data.accessToken) {
                       localStorage.setItem('token', data.accessToken);
                       localStorage.setItem('refreshToken', data.refreshToken);
                       this.loggedIn$.next(true);
                       this.loggedIn = true;
                       return true;
                   } else {
                       return false;
                   } 
               },
            error=>{
               return false;
            },
            
           );
       }

    isLoggedIn(): Promise<boolean> {

        let localToken = localStorage.getItem('token');
        if (!localToken) {
            return Promise.resolve(false);
        } else {
            const decodedToken = this.helper.decodeToken(localToken);

            const isExpired = this.helper.isTokenExpired(localToken);
            if(decodedToken && !isExpired)
           return Promise.resolve(true);
            else{
              this.userToken = new TokenModel();
              let refreshToken = localStorage.getItem('refreshToken');
              this.userToken.userName = decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
              this.userToken.refreshToken = refreshToken;
           return  this.refreshToken(this.userToken).then(x=>{
               if(!x){
                localStorage.clear();
               }
               else
                return x;
             });

               
            }
        }
    }
}