<div class="" id="page-wrapper">
    <div class="container-fluid">
      <div class="row row-stat">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-md-12">
          <div class="panel panel-default panel-table">
            <div class="panel-heading panel-heading-no-divider">Evrak Bilgileri<span class="panel-subtitle">Sisteme kayıtlı gelen giden tüm evraklar listeleniyor.</span>
              <button [hidden]="isAdd" (click)="newRecord()" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-plus"></i> Yeni Ekle</button>
              <button [hidden]="!isAdd" (click)="isAdd=false" class="btn btn-space btn-danger"><i class="icon mdi mdi-close-circle"></i> Vazgeç</button>
            </div>
                   
                <div [hidden]="isAdd" class="table-responsive">
                    <table #dataTable name="dataTable" datatable [dtOptions]="dtOptions"  [dtTrigger]="dtTrigger" class="row-border hover">
                        <thead>
                          <tr>
                            <th>Tarih</th>
                            <th>Evrak No</th>
                            <th>Açıklama</th>
                            <th>Evrak Tipi</th>
                                <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let invoice of invoiceList">
                            <td>{{invoice.date | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                            <td>{{invoice.documentNo}}</td>
                            <td>{{invoice.explanation}}</td>
                            <td>{{invoice.typeDesc}}</td>
                            <td><button (click)="showDetails(invoice)" class="btn btn-lg btn-outline-primary">Detay</button></td>
                          </tr>                      
                        </tbody>
                      </table>
                </div>  

                <ng-template #detailModal let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Evrak Detayları</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form>
                      <div class="panel panel-default">
                        <div class="panel-heading panel-heading-no-divider">{{selectedInvoice.documentNo}}<span class="panel-subtitle">Evrak içerisindeki ürünler liteleniyor. </span></div>
                             <div class="table-responsive">
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Ürün Kodu</th>
                                    <th>Ürün Adı</th>
                                    <th>Miktar</th>
                                    <th>Birim</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let item of selectedInvoice.invoiceDetails">
                                    <td>{{item.productCode}}</td>
                                    <td>{{item.productName}}</td>
                                    <td>{{item.quantity}}</td>
                                    <td>{{item.unitDesc}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                    </form>
                  </div>
                  <div class="modal-footer">
            
                  </div>
                </ng-template>   
                
          <ng-template #selectProductModal let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Ürün Listesi</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <form>
                  <div class="panel panel-default">
                    <div class="panel-heading panel-heading-no-divider">Ürünler<span class="panel-subtitle">Sistemde kayıtlı tüm ürünler listelenmektedir.</span></div>
                          
                 
                  <input type="text" #searchKey />
                    <button type="button" class="btn btn-outline-dark" (click)="searchProduct(searchKey.value===null ? '' :searchKey.value ,-1)">Ara</button>
              
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th>Ürün Kodu</th>
                                <th>Ürün Adı</th>
                                <th>Adet</th>
                                <th>Birim</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of searchProductList">
                                <td>{{item.code}}</td>
                                <td>{{item.name}}</td>
                                <td><input class="form-control input-pill" [(ngModel)]="item.quantity" type="number" name="quantity"/></td>
                                <td>{{item.unit}}</td>
                                <td><button class="btn btn-lg btn-outline-primary" (click)="selectProduct(item);">Seç</button></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                </form>
           
            </div>
            <div class="modal-footer">
            </div>
          </ng-template>
      
  
                 <div [hidden]="!isAdd" class="panel-body">
                    <div class="xs-mt-10 xs-mb-10  ">
                      <div class="row">
                        <div class="col-md-8">
                          <form class="form-horizontal">
                            <div class="form-group">
                              <div class="row">
                                <label class="col-sm-3 control-label" for="form-control-2">Depo</label>
                                <div class="col-sm-6">
                                  <select  [(ngModel)]="selectedInvoice.warehouseId" name="type" class="form-control">
                                    <option *ngFor="let item of warehouseList" [ngValue]="item.id">{{item.description}}</option>                       
                                  </select>
                                  </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="row">
                                <label class="col-sm-3 control-label" for="form-control-2">Evrak Tipi</label>
                                <div class="col-sm-6">
                                  <select  [(ngModel)]="selectedInvoice.type" name="type" class="form-control">
                                    <option [ngValue]="0">Alış Faturası</option>                       
                                    <option [ngValue]="1">Satış Faturası</option>                       
                                  </select>
                                  </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="row">
                                <label class="col-sm-3 control-label" for="form-control-2">Evrak Numarası</label>
                                <div class="col-sm-6">
                                  <input [(ngModel)]="selectedInvoice.documentNo" name="documentNo" class="form-control input-pill" type="text">
                                  </div>
                              </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                  <label class="col-sm-3 control-label" for="form-control-2">Evrak Tarihi</label>
                                  <div class="col-sm-6">                                    
                                    <div class="input-group">
                                      <input autocomplete="off" class="form-control" (click)="d.toggle()" placeholder="yyyy-mm-dd"
                                             name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker">
                                      <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="row">
                                  <label class="col-sm-3 control-label" for="form-control-2">Açıklama</label>
                                  <div class="col-sm-6">
                                    <textarea [(ngModel)]="selectedInvoice.explanation" name="explanation" class="form-control input-pill" type="text"></textarea>
                                  </div>
                                </div>
                              </div>
                              
                              <button (click)="selectProductShow()" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-plus"></i> Ürün Ekle</button>
                              <button [hidden]="selectedInvoice.invoiceDetails.length===0" (click)="addInvoice()" class="btn btn-space btn-success"><i class="icon icon-left mdi mdi-check"></i> Kaydet</button>
                              <div [hidden]="selectedInvoice.invoiceDetails.length===0" class="panel panel-default">                           
                                <div class="panel-heading panel-heading-no-divider"><span class="panel-subtitle">Eklenen ürünler listeleniyor. </span></div>
                                    <div class="table-responsive">
                                       <table class="table table-striped">
                                        <thead>
                                          <tr>
                                            <th>Ürün Kodu</th>
                                            <th>Ürün Adı</th>
                                            <th>Adet</th>
                                            <th>Birim</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let item of selectedInvoice.invoiceDetails">
                                            <td>{{item.productCode}}</td>
                                            <td>{{item.productName}}</td>
                                            <td>{{item.quantity}}</td>
                                            <td>{{item.unitDesc}}</td>
                                            <td><button class="btn btn-lg btn-outline-primary" (click)="removeProduct(item);">Sil</button></td>
                                          </tr>
                                        </tbody>
                                      </table> 
                                    </div>
                                  </div> 
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>   
                  <ng-snotify></ng-snotify>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  