import { Injectable } from "@angular/core";

@Injectable()
export class WarehouseTransfer
{
    fromWarehouseid:number;
    toWarehouseid:number;
    productId:number;
    unitId:number;
    quantity:number;
    productCode:string;
    productName:string;
    unitDesc:string;
    createId:number=1;
    }