import { Injectable } from "@angular/core";
import { NumberValueAccessor } from "@angular/forms";

@Injectable()
export class TokenModel
{
    accessToken:string;
    expiration:Date;
    refreshToken:string;
    userName:string;
    userId:number;
    nameSurname:string;
    permissionItems:string;
}