import { Injectable } from "@angular/core";
import { PrescriptionDetail } from "./prescription.detail.model";

@Injectable()
export class Prescription
{
    id:number; 
        productCode:string;
        productName:string;
        productId:number=0;
        unit:string;
        warehouseId:number;
        warehouseName:string;
        unitId:number;
        createUserName:string; 
        createId:number; 
        quantity:number;
        prescriptionId:number;
        totalQuantity:number;
        prescriptionDetails:PrescriptionDetail[]=[];     
        deleted:boolean;  
}