import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth.service';
import { Product } from '../services/product.model';
import { ProductTransaction, ProductTransactionList } from '../services/productTransaction.model';
import { ServiceRepository } from '../services/service.repository';
import { Warehouse } from '../services/warehouse.model';
import { WarehouseQuantity } from '../services/warehousequantity.model';
import { WarehouseTransfer } from '../services/warehousetransfer.model';


@Component({
  selector: 'app-warehousetransfer',
  templateUrl: './warehousetransfer.component.html',
  styleUrls: ['./warehousetransfer.component.scss']
})
export class WarehousetransferComponent implements OnInit {

  constructor(private loading: NgxSpinnerService, private auth: AuthService, private datePipe: DatePipe, private snotifyService: SnotifyService, private serviceRepository: ServiceRepository, private modalService: NgbModal, private calendar: NgbCalendar) { this.au = auth; }
  @ViewChild('selectProductModal', { static: true }) selectProductModal: ModalDirective;
  isAdd: boolean;
  au: AuthService;
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  warehouseList: Warehouse[] = [];
  toWarehouse: Warehouse = new Warehouse();
  transferNote: string;
  fromWarehouse: Warehouse = new Warehouse();
  transferList: WarehouseTransfer[] = [];
  transactionList: ProductTransactionList[] = [];
  transactionListTmp: ProductTransactionList[] = [];
  transferItem: WarehouseTransfer;
  searchProductList: Product[] = [];
  quantity: WarehouseQuantity;
  page: number = 1;
  pageSize: number = 10;
  transactionDetailList: ProductTransaction[] = [];

  collectionSize: number;
  refreshCountries() {
    this.transactionListTmp = this.transactionList
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  showModelByName(content, list: ProductTransaction[]) {
    this.transactionDetailList = list;
    this.modalService.open(content, { size: 'xl' });
  }

  tableDatePerm: boolean = false;
  tableDocumentNo: boolean = false;
  tablecreateUserName: boolean = false;
  tabledetail: boolean = false;

  ngOnInit(): void {

    this.tableDatePerm = !this.au.checkPermission('controls:warehouseTransfer:table:date');
    this.tableDocumentNo = !this.au.checkPermission('controls:warehouseTransfer:table:documentNo');
    this.tablecreateUserName = !this.au.checkPermission('controls:warehouseTransfer:table:createUserName');
    this.tabledetail = !this.au.checkPermission('controls:warehouseTransfer:table:detail');


    this.startDate = this.calendar.getToday();
    this.startDate = { year: this.startDate.year, day: 1, month: this.startDate.month };
    this.endDate = this.calendar.getToday();
    this.getTransferList();
  }

  selectTransfer(item: Product): void {
    if (item.quantity > this.getQuantity(item, this.fromWarehouse.id)) {
      this.snotifyService.error("Depoda olan miktardan fazla transfer yapılamaz...");
      return;
    }
    this.transferItem = { createId: 1, quantity: item.quantity, fromWarehouseid: this.fromWarehouse.id, toWarehouseid: this.toWarehouse.id, productId: item.id, productCode: item.code, productName: item.name, unitId: item.unitId, unitDesc: item.unit }
    console.log(this.transferItem);
    this.transferList.push(this.transferItem);
  }
  removeTransfer(item: WarehouseTransfer): void {
    this.transferList.splice(this.transferList.indexOf(item), 1);
    this.snotifyService.success("İşlem başarılı...");
  }

  addTransfer(succses: boolean): void {
    if (succses) {
      this.loading.show();
      this.serviceRepository.addWarehouseTransfer(this.transferList, this.transferNote).then(x => {
        this.transferList = [];
        this.getTransferList();
        this.loading.hide();
        this.snotifyService.success("İşlem başarılı...");
        this.toWarehouse = new Warehouse();
        this.fromWarehouse = new Warehouse();
        this.transferNote = '';
        this.isAdd = false;
      })
    }
    else {
      this.snotifyService.confirm('Seçili depolar arasında ürünlerin transferi olacaktır. Onaylıyor musunuz?', 'Uyarı!!!', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        buttons: [
          { text: 'Yes', action: () => this.addTransfer(true), bold: false },
          { text: 'No', action: () => console.log('Clicked: No') },
        ]
      });
    }
  }

  selectProductShow(): void {
    this.searchProductList = [];
    this.modalService.open(this.selectProductModal, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
  }

  getQuantity(item: Product, id: number): number {
    this.quantity = item.warehouseQuantitys.filter(x => x.warehouseId == id)[0];
    return (this.quantity === undefined ? 0 : this.quantity.quantity);
  }

  searchProduct(key: string, type: number) {
    this.loading.show();
    this.serviceRepository.productSearchCodeOrName(key, type, -1).subscribe(x => {
      if (x.length === 1) x[0].quantity = 0;
      this.searchProductList = x;
      this.loading.hide();
    });
  }


  newRecord(): void {
    this.transferItem = new WarehouseTransfer();
    this.isAdd = true;
  }

  selectedWarehouseId: number = -1;

  getWarehouseList(): void {
    this.serviceRepository.getWarehouses().then(x => { this.warehouseList = x; this.loading.hide(); });
  }
  getTransferList(): void {
    this.loading.show();
    let startDate = new Date(this.startDate.year, this.startDate.month - 1, this.startDate.day);
    let endDate = new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day);
    this.serviceRepository.getTransferList(startDate, endDate, this.selectedWarehouseId).then(x => {
      this.transactionList = x;
      this.refreshCountries();
      this.collectionSize = x.length;
      this.getWarehouseList();
    });
  }

  public print(item: ProductTransactionList): void {
    let data = '<h2 class="title">Oto Erbaylar</h2>' +
      '<p>Tarih: ' + this.datePipe.transform(item.createDate, 'yyyy-MM-dd HH:mm:ss') + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
      'Evrak Numarası: ' + item.documentNo + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
      'İşlem Yapan: ' + item.createUserName + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>' +
      '<p>Çıkış Depo: ' + item.from + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
      'Giriş Depo: ' + item.to + '</p>' +
      '<div class="table-responsive"><table class="table table-sm table-striped table-hover">' +
      '<thead><tr><th class="text-center">Tarih</th>' +
      '<th class="text-center">Ürün Kodu</th>' +
      '<th class="text-center">Ürün Adı</th>' +
      '<th class="text-center">Adet</th>' +
      '<th class="text-center">Birim</th>' +
      '<th class="text-center">Hareket Tipi</th>' +
      '<th class="text-center">Depo Adı</th>' +
      '</tr></thead><tbody>';
    item.transferList.forEach(item => {
      data += '<tr>' +
        '<td class="text-center">' + this.datePipe.transform(item.createDate, 'yyyy-MM-dd HH:mm:ss') + '</td>' +
        '<td class="text-center">' + item.productCode + '</td>' +
        '<td class="text-center">' + item.productName + '</td>' +
        '<td class="text-center">' + item.quantity + '</td>' +
        '<td class="text-center">' + item.unit + '</td>' +
        '<td class="text-center">' + item.transactionType + '</td>' +
        '<td class="text-center">' + item.warehouseName + '</td>' +
        '</tr>';
    });
    data += '</tbody></table></div>';

    this.printPage(data);
  }

  private printPage(data: string): void {
    const windowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    windowPrt.document.write('<html><head><style type="text/css">' +
      '.title { text-align: center; }' +
      'h2, h4, p, div, table { font-family: "Arial", "PT Sans" !important;' +
      'list-style: none; }' +
      '.table { width: 100%; margin-bottom: 1rem; color: #212529; border-collapse: collapse; } th, td { padding: .3rem; text-align: center; border: 1px solid #dee2e6; }' +
      '</style></head><body>' + data +
      '</body></html>');
    windowPrt.document.close();
    windowPrt.focus();
    setTimeout(function () {
      windowPrt.print();
    }, 1000);
  }
}
