import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RestService } from './rest.service';
import { ServiceRepository } from './service.repository';
import { AuthService } from './auth.service';
import { AuthGuardService } from './loginguard.service';
import { TranslateService } from './translate.service';

@NgModule({
    declarations: [],
    imports: [ HttpClientModule ],
    exports: [],
    providers: [RestService,ServiceRepository,AuthService,AuthGuardService,TranslateService],
})
export class ServiceModule {}