import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faLiraSign, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerInvoice } from '../services/customer.invoice.model';
import { CustomerInvoiceDetail } from '../services/customer.invoicedetail.model';
import { Customer } from '../services/customer.model';
import { Invoice } from '../services/invoice.model';
import { AuthCustomer } from '../services/page.auth.model';
import { ServiceRepository } from '../services/service.repository';
import { TranslateService } from '../services/translate.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  authCustomer:AuthCustomer = {};
  isAdd = false;
  customerList:Customer[];
  dataSource: MatTableDataSource<Customer>;
  tableColumns:string[] = [];
  selectedCustomer:Customer;
  invoiceList:CustomerInvoice[] = [];
  invoiceDetailList:CustomerInvoiceDetail[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('customerInvoice', { static: false }) customerInvoice: ModalDirective;
  @ViewChild('invoiceDetail', { static: false }) invoiceDetail: ModalDirective;
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  totalBalance:number;
  faPlus = faPlusCircle;
  selectedInvoice:CustomerInvoice;

  constructor(private translateService:TranslateService,private serviceRepository:ServiceRepository,private modalService:NgbModal,private spinner: NgxSpinnerService) { 
    this.dataSource = new MatTableDataSource();
    this.serviceRepository.getUserPageAuth(this.constructor.name.replace('Component','')).then(x=>{
      this.authCustomer = x;  
        this.tableColumns = [];
      Object.keys(this.authCustomer).forEach((key) => {
        if(key.startsWith('table'))
        this.tableColumns.push(key);
      })
    });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLocaleLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  getInvoiceDetail(invoice:CustomerInvoice):void{
    this.spinner.show();
    this.selectedInvoice = invoice;
    debugger;
    this.serviceRepository.getCustomerInvoiceDetailList(invoice.documentNo,this.selectedCustomer.logicalRef).then(x=>{
      this.invoiceDetailList = x;
      this.modalService.open(this.invoiceDetail, {ariaLabelledBy: 'modal-basic-title',size:'xl'}); 
      this.spinner.hide();
    });
  }

  ngOnInit(): void {
    this.getCustomerList();
  }
  getCustomerList():void{
    this.spinner.show();
    this.serviceRepository.getCustomerList().then(x=>{
      this.customerList = x;
      this.dataSource =new MatTableDataSource(x);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = 
  (data: Customer, filter: string) => (data.code.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!=-1 
  || data.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!=-1
  || data.address.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!=-1
  || data.phone.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!=-1
  );
      this.paginator._intl.itemsPerPageLabel = this.translateService.getLanguage("Items per page","tr");
      this.paginator._intl.lastPageLabel = this.translateService.getLanguage(this.paginator._intl.lastPageLabel,"tr");
      this.paginator._intl.previousPageLabel = this.translateService.getLanguage(this.paginator._intl.previousPageLabel,"tr");
      this.paginator._intl.nextPageLabel = this.translateService.getLanguage(this.paginator._intl.nextPageLabel,"tr");
      this.spinner.hide();
    });
  }

  getInvoiceList(customer:Customer):void{
    this.selectedCustomer = customer;
    let date:Date = new Date();
    this.endDate = {year:date.getFullYear(),day:date.getDate(),month:date.getMonth()+1};
    this.startDate = {year:date.getFullYear(),day:1,month:1};
    this.getInvoices();
    this.modalService.open(this.customerInvoice, {ariaLabelledBy: 'modal-basic-title',size:'xl'});
  }

  getInvoices():void{
    this.spinner.show();
    let startDate = new Date(this.startDate.year,this.startDate.month-1,this.startDate.day);
    let endDate = new Date(this.endDate.year,this.endDate.month-1,this.endDate.day);
    this.serviceRepository.getCustomerInvoiceList(this.selectedCustomer.code,startDate,endDate).then(x=>{
      this.invoiceList = x;
      this.invoiceList = x.filter(t=>t.transactionType!='TotalBalance');
      if(x.length>0)
      this.totalBalance = x[0].totalBalance;   
      this.spinner.hide(); 
    });
  }



}
