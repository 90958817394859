import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  s:string;
  constructor(private auth: AuthService, private router: Router) {  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.auth.isLoggedIn().then(x=>{
      if(!x){
        this.router.navigate(['login']);
        return  ;
      }
      else if(!this.auth.checkPermission('page:'+state.url.replace('/',''))){
        this.router.navigate([this.auth.firstPermission()]);
        return ;
      }
      else
      return x;
    })
  }
 
}