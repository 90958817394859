import { Injectable } from "@angular/core";
import { PrescriptionDetail } from "./prescription.detail.model";
import { ProductTransaction } from "./productTransaction.model";

@Injectable()
export class Manufacture
{
        id:number;
        prescriptionId:number; 
        productCode?:string;
        productName?:string;
        quantity:number;
        completedQuantity?:number;
        plainDate:Date;
        completeQuantity?:number;
        unit?:string;
        statusId:number;
        manufacturePlainId:number;
        createUserName?:string; 
        startDate:Date; 
        startedUserName:string;
        warehouseId:number;
        finishDate?:Date;
        prescriptionDetails?:PrescriptionDetail[];
        createId:number;
        isAllComplete:boolean=false;
        productTransactions?:ProductTransaction[];
}


@Injectable()
export class ReturnManufacture
{
        notCompleted:Manufacture[];
        completed:Manufacture[];
}