<div class="" id="page-wrapper">
  <div class="container-fluid">
    <div class="row row-stat">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-md-12">
            <div class="panel panel-default panel-table">
              <div class="panel-heading panel-heading-no-divider">
                <span>Ürünler - Sistemde kayıtlı tüm ürünler listelenmektedir.</span>
                <div [hidden]="!authProduct.admin && !authProduct.btnNewItem">
                  <button [hidden]="isAdd" (click)="newRecord(true)" class="btn btn-space btn-success"><i
                      class="icon icon-left mdi mdi-plus"></i> Yeni Ekle</button>
                  <button [hidden]="!isAdd" (click)="newRecord(false)" class="btn btn-space btn-danger"><i
                      class="icon mdi mdi-close-circle"></i> Vazgeç</button>
                </div>
              </div>
              <ng-template #htmlContent>
                <div style="width: 300px;height: auto;" *ngFor="let item of warehouseList">
                  <div class="col-md-12">
                    <div class="col-md-4">{{item.warehouseDesc}}</div>
                    <div class="col-md-4">{{item.quantity}}</div>
                  </div>
                </div>
              </ng-template>
              <ng-template #productTransaction let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">Stok Hareketleri</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div style="display: contents;" class="table-responsive">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-5">
                        <div class="row">
                          <label class="col-sm-3 control-label" for="form-control-2">Başlangıç Tarihi</label>
                          <div class="col-sm-6">
                            <div class="input-group">
                              <input autocomplete="off" [(ngModel)]="startDate" class="form-control" (click)="d.toggle()"
                                placeholder="yyyy-mm-dd" name="dpStart" ngbDatepicker #d="ngbDatepicker">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                  type="button"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="row">
                          <label class="col-sm-3 control-label" for="form-control-2">Bitiş Tarihi</label>
                          <div class="col-sm-6">
                            <div class="input-group">
                              <input autocomplete="off" [(ngModel)]="endDate" class="form-control" (click)="dEnd.toggle()"
                                placeholder="yyyy-mm-dd" name="dpEnd" ngbDatepicker #dEnd="ngbDatepicker">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dEnd.toggle()"
                                  type="button"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <button class="btn btn-outline-secondary calendar" 
                        type="button" (click)="getTransactions();">Listele</button>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="modal-body">
                  <form>
                    <div class="panel panel-default">
                      <div class="panel-heading panel-heading-no-divider">{{selectedProduct.code}}<span
                          class="panel-subtitle">{{selectedProduct.name}} ürnüne ait hareketler listelenmektedir.</span>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Tarih</th>
                              <th>Ürün Kodu</th>
                              <th>Ürün Adı</th>
                              <th>Adet</th>
                              <th>Birim</th>
                              <th>İşlem</th>
                              <th>Depo Adı</th>
                              <th>Hareket Tipi</th>
                              <th>İşlemi Yapan</th>
                              <th>Evrak No</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of transactionList">
                              <td>{{item.createDate | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                              <td>{{item.productCode}}</td>
                              <td>{{item.productName}}</td>
                              <td>{{item.quantity}}</td>
                              <td>{{item.unit}}</td>
                              <td>{{item.type}}</td>
                              <td>{{item.warehouseName}}</td>
                              <td>{{item.transactionType}}</td>
                              <td>{{item.createUserName}}</td>
                              <td>{{item.documentNo}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>

                </div>
                <div class="modal-footer">
                </div>
              </ng-template>
             
              <ng-template #productionTransaction let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">{{selectedProduct.code}} Ürününün Üretim Adedi</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div style="display: contents;" class="table-responsive">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-5">
                        <div class="row">
                          <label class="col-sm-3 control-label" for="form-control-2">Başlangıç Tarihi</label>
                          <div class="col-sm-6">
                            <div class="input-group">
                              <input autocomplete="off" [(ngModel)]="startDate" class="form-control" (click)="d.toggle()"
                                placeholder="yyyy-mm-dd" name="dpStart" ngbDatepicker #d="ngbDatepicker">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                  type="button"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="row">
                          <label class="col-sm-3 control-label" for="form-control-2">Bitiş Tarihi</label>
                          <div class="col-sm-6">
                            <div class="input-group">
                              <input autocomplete="off" [(ngModel)]="endDate" class="form-control" (click)="dEnd.toggle()"
                                placeholder="yyyy-mm-dd" name="dpEnd" ngbDatepicker #dEnd="ngbDatepicker">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dEnd.toggle()"
                                  type="button"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <button class="btn btn-outline-secondary calendar" 
                        type="button" (click)="getProductQuantities();">Listele</button>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="modal-body">
                  <form>
                    <div class="panel panel-default">
                      <div class="panel-heading panel-heading-no-divider">{{selectedProduct.name}} ürününe ait üretim adedi :<span
                          class="panel-subtitle"> {{response}}</span>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading panel-heading-no-divider">{{selectedProduct.name}} ürününe ait hurda deposuna sevk adedi :<span
                          class="panel-subtitle">{{scrapQuantities}}</span>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading panel-heading-no-divider">{{selectedProduct.name}} ürününe ait arızalı kontrol deposuna sevk adedi :<span
                          class="panel-subtitle">{{faultQuantities}}</span>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading panel-heading-no-divider">{{selectedProduct.name}} ürününe ait arızalı kontrol deposuna sevk adedi :<span
                          class="panel-subtitle">{{rate | number :'1.2-2'}}</span>
                      </div>
                    </div>
                  </form>
                </div>
              </ng-template>


<div [hidden]="isAdd" class="col-md-12">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" class="form-control ng-valid ng-dirty ng-touched" type="text" placeholder="Arama..."/>
  </mat-form-field>
    
    <div class="table table-striped">
    
      <mat-table class="table table-striped" [dataSource]="dataSource">
    
        <!-- ID Column -->
        <ng-container  matColumnDef="tableId">
          <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
          <mat-cell [ngClass]="row.criticalLevelInfo ? 'bgColorRed' : ''" *matCellDef="let row"> {{row.id}} </mat-cell>
        </ng-container>
    
        <!-- Progress Column -->
        <ng-container matColumnDef="tableCode">
          <mat-header-cell *matHeaderCellDef> Ürün Kodu </mat-header-cell>
          <mat-cell [ngClass]="row.criticalLevelInfo ? 'bgColorRed' : ''" *matCellDef="let row"> {{row.code}} </mat-cell>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="tableName">
          <mat-header-cell *matHeaderCellDef > Ürün Adı </mat-header-cell>
          <mat-cell [ngClass]="row.criticalLevelInfo ? 'bgColorRed' : ''" *matCellDef="let row"> {{row.name}} </mat-cell>
        </ng-container>
    
        <!-- Color Column -->
        <ng-container matColumnDef="tableProductType">
          <mat-header-cell *matHeaderCellDef > Ürün Tipi </mat-header-cell>
          <mat-cell [ngClass]="row.criticalLevelInfo ? 'bgColorRed' : ''" *matCellDef="let row"> {{row.type}} </mat-cell>
        </ng-container>
        <ng-container *ngIf="authProduct.tableQuantity" matColumnDef="tableQuantity">
          <mat-header-cell *matHeaderCellDef > Depo Miktarı </mat-header-cell>
          <mat-cell [ngClass]="row.criticalLevelInfo ? 'bgColorRed' : ''" *matCellDef="let row" [ngbTooltip]="row.quantity!=0 ? htmlContent : ''" (mouseover)="row.quantity!=0 ? getQuantityList(row.id) : null" container="body"> 
            {{row.quantity}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tableCritical">
          <mat-header-cell *matHeaderCellDef > Kritik Seviye </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <input [ngClass]="row.criticalLevelInfo ? 'bgColorRed' : ''" style="width: 80px;text-align: center;" [(ngModel)]="row.criticalLevel" class="form-control input-pill" type="number"/>
            <button class="btn btn-sm btn-success" (click)="updateCriticalLevel(row);">Güncelle</button>
          </mat-cell>
        </ng-container>

        <ng-container *ngIf="authProduct.tableProductTransaction"  matColumnDef="tableProductTransaction">
          <mat-header-cell *matHeaderCellDef> Stok Hareketleri </mat-header-cell>
          <mat-cell *matCellDef="let row"> 
            <button class="btn btn-lg btn-primary btn-sm btn-block" (click)="showTransactionModal(row);">Stok
              Hareketleri</button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tableProductionTransaction">
          <mat-header-cell *matHeaderCellDef> Üretim Adedi </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button class="btn btn-lg btn-primary btn-sm btn-block" (click)="showProductionModal(row);">Üretim Adedi</button>
          </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="tableAppearInReport">
          <mat-header-cell *matHeaderCellDef> Raporda Görünsün Mü? </mat-header-cell>
          <mat-cell *matCellDef="let row"> 
            <input class="form-check-input" type="checkbox" (click)="changeAppear(row.id);" [(ngModel)]="row.appearInReport" id="flexCheckDefault">
          </mat-cell>
        </ng-container>
    
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns;">
        </mat-row>
      </mat-table>
    
      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
</div>









              <div [hidden]="!isAdd" class="panel-body">
                <div class="xs-mt-10 xs-mb-10  ">
                  <div class="row">
                    <div class="col-md-8">
                      <form class="form-horizontal">
                        <div class="form-group">
                          <div class="row">
                            <label class="col-sm-3 control-label" for="form-control-2">Ürün Kodu</label>
                            <div class="col-sm-9">
                              <input [(ngModel)]="product.code" name="code" class="form-control input-pill" type="text">
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="row">
                            <label class="col-sm-3 control-label" for="form-control-2">Ürün Adı</label>
                            <div class="col-sm-9">
                              <input [(ngModel)]="product.name" name="name" class="form-control input-pill" type="text">
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="row">
                            <label class="col-sm-3 control-label" for="form-control-9">Ürün Tipi</label>
                            <div class="col-sm-9">
                              <select [(ngModel)]="product.typeId" name="type" class="form-control">
                                <option *ngFor="let productType of productTypeList" [ngValue]="productType.id">
                                  {{productType.description}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="row">
                            <label class="col-sm-3 control-label" for="form-control-9">Birim</label>
                            <div class="col-sm-9">
                              <select [(ngModel)]="product.unitId" name="unit" class="form-control">
                                <option *ngFor="let unit of unitList" [ngValue]="unit.id">{{unit.description}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <button (click)="saveProduct(product)" class="btn btn-space btn-success"><i
                            class="icon icon-left mdi mdi-check"></i> Kaydet</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <ng-snotify></ng-snotify>


              <!-- <div [hidden]="isAdd" class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th [hidden]="!authProduct.admin && !authProduct.tableId">Id</th>
                      <th [hidden]="!authProduct.admin && !authProduct.tableProductCode">Ürün Kodu</th>
                      <th [hidden]="!authProduct.admin && !authProduct.tableProductName">Ürün Adı</th>
                      <th [hidden]="!authProduct.admin && !authProduct.tableProductType">Ürün Tipi</th>
                      <th [hidden]="!authProduct.admin && !authProduct.tableQuantity">Depo Miktarı</th>
                      <th [hidden]="!authProduct.admin && !authProduct.tableProductTransaction">Stok Hareketleri</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of productList | productFilter:filterText">
                      <td [hidden]="permTableId">{{product.id}}</td>
                      <td>{{product.code}}</td>
                      <td>{{product.name}}</td>
                      <td>{{product.type}}</td>
                      <td [ngbTooltip]="product.quantity>0 ? htmlContent : ''" (mouseover)="product.quantity>0 ? getQuantityList(product.id) : null" container="body">
                        <span>{{product.quantity}}</span>
                      </td>
                      <td><button class="btn btn-lg btn-primary btn-sm btn-block" (click)="getTransaction(product);">Stok
                          Hareketleri</button></td>
                    </tr>

                  </tbody>
                </table>
                <div class="d-flex justify-content-between p-2">
                  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="refreshCountries()">
                      </ngb-pagination>
                
                  <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" name="pageSize" (ngModelChange)="refreshCountries()">
                    <option [ngValue]="5">5 li listele</option>
                    <option [ngValue]="10">10 lu listele</option>
                    <option [ngValue]="25">25 li listele</option>
                  </select>
                </div>
              </div> -->



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>