import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerInvoice } from './customer.invoice.model';
import { CustomerInvoiceDetail } from './customer.invoicedetail.model';
import { Customer } from './customer.model';
import { InvoiceDetail } from './invoice.detail.model';
import { Invoice } from './invoice.model';
import { Login } from './Login.model';
import { Manufacture, ReturnManufacture } from './manufacture.model';
import { ManufacturePlain } from './manufactureplain.model';
import { PlainFuture } from './plain.future.model';
import { PrescriptionDetail } from './prescription.detail.model';
import { Prescription } from './prescription.model';
import { Product } from './product.model';
import { ProductTransaction, ProductTransactionList } from './productTransaction.model';
import { ProductType } from './productType.model';
import { TokenModel } from './token.model';
import { Unit } from './unit.model';
import { User } from './user.model';
import { UserAuthorization } from './userauthorization.model';
import { UserRole } from './userrole.model';
import { Warehouse } from './warehouse.model';
import { WarehouseOwner } from './warehouseowner.model';
import { WarehouseQuantity } from './warehousequantity.model';
import { WarehouseTransfer } from './warehousetransfer.model';
import { param } from 'jquery';
import { ProductTransactionQuantity } from './productTransactionQuantity.model';

@Injectable()
export class RestService {
  //old v = 1.0.1.0
  private url = "https://service.otoerbaylar.com.tr/api/";
  //private url = "http://localhost:18522/api/";
  token: string;
  constructor(private http: HttpClient) { }


  getUserList(): Observable<User[]> {
    return this.http.get<User[]>(`${this.url}User`);
  }

  getCustomerList(): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${this.url}Customer`);
  }

  getCustomerInvoiceList(customerCode: string, startDate: Date, endDate: Date): Observable<CustomerInvoice[]> {
    let params = new HttpParams();
    let object = { date1: startDate, date2: endDate }
    params = params.append('customerCode', customerCode);
    params = params.append('startDate', object.date1.toISOString());
    params = params.append('endDate', object.date2.toISOString());
    return this.http.get<CustomerInvoice[]>(`${this.url}Customer/GetInvoiceList`, { params: params });
  }


  getCustomerInvoiceDetailList(documentNo: string, customerLogicalRef: number): Observable<CustomerInvoiceDetail[]> {
    let params = new HttpParams();
    params = params.append('documentNo', documentNo);
    params = params.append('customerLogicalRef', customerLogicalRef.toString());
    return this.http.get<CustomerInvoiceDetail[]>(`${this.url}Customer/GetInvoiceDetailList`, { params: params });
  }



  getUserAuthorizationListByUserId(userId: number): Observable<UserAuthorization[]> {
    return this.http.get<UserAuthorization[]>(`${this.url}User/GetAuthorizationList/${userId}`);
  }
  getWarehouseListByUserId(userId: number): Observable<WarehouseOwner[]> {
    return this.http.get<WarehouseOwner[]>(`${this.url}User/GetWarehouseList/${userId}`);
  }

  getUserRoleListByUserId(userId: number): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(`${this.url}User/GetRoleList/${userId}`);
  }


  addOrEditUser(item: User): Observable<User> {
    return this.http.post<User>(`${this.url}User/EditOrAddUser`, item);
  }

  editOrAddAuthorization(item: UserAuthorization): Observable<UserAuthorization> {
    return this.http.post<UserAuthorization>(`${this.url}User/EditOrAddAuthorization`, item);
  }

  editOrAddRole(item: UserRole): Observable<UserRole> {
    return this.http.post<UserRole>(`${this.url}User/EditOrAddRole`, item);
  }

  editOrAddWarehouseOwner(item: WarehouseOwner): Observable<WarehouseOwner> {
    return this.http.post<WarehouseOwner>(`${this.url}User/EditOrAddWarehouseOwner`, item);
  }



  getManufactureList(startDate: Date, endDate: Date, warehouseId: number): Observable<ReturnManufacture> {
    let object = { date1: startDate, date2: endDate }
    let params = new HttpParams();
    params = params.append('startDate', object.date1.toISOString());
    params = params.append('endDate', object.date2.toISOString());
    params = params.append('warehouseId', warehouseId.toString());
    debugger;
    return this.http.get<ReturnManufacture>(`${this.url}Manufacture`, { params: params });
  }

  getProductQuantityList(productId: number): Observable<WarehouseQuantity[]> {
    return this.http.get<WarehouseQuantity[]>(`${this.url}Product/GetWarehouseQuantityList/${productId}`);
  }

  getPrescriptionList(): Observable<Prescription[]> {
    return this.http.get<Prescription[]>(`${this.url}Prescription`);
  }

  getPrescriptionListByProductId(productId: number, startDate: Date): Observable<number> {
    let object = { productId: productId, startDate: startDate }
    let params = new HttpParams();
    params = params.append('productId', object.productId.toString());
    params = params.append('startDate', object.startDate.toISOString());
    return this.http.get<number>(`${this.url}Prescription/GetListByProductId`, { params: params });
  }

  getPrescriptionActiveList(): Observable<Prescription[]> {
    return this.http.get<Prescription[]>(`${this.url}Prescription/GetOnlyActive`);
  }

  getPrescriptionListByWarehouseId(id: number): Observable<Prescription[]> {
    return this.http.get<Prescription[]>(`${this.url}Prescription/GetListByWarehouseId/${id}`);
  }


  getPrescriptionDetailListById(id: number): Observable<PrescriptionDetail[]> {
    return this.http.get<PrescriptionDetail[]>(`${this.url}Prescription/GetDetailListById/${id}`);
  }
  
  getPrescriptionDetailListByWarehouseId(id: number, warehouseId: number): Observable<PrescriptionDetail[]> {
    let object = {id : id, warehouseId: warehouseId};
    let params = new HttpParams();
    params = params.append('id', object.id.toString());
    params = params.append('warehouseId', object.warehouseId.toString());
    return this.http.get<PrescriptionDetail[]>(`${this.url}Prescription/GetDetailListByWarehouseId`, { params : params});
  }

  getPrescriptionIdByProductId(productId: number,startDate:Date): Observable<number> {
    let object = { productId: productId, startDate: startDate};
    let params = new HttpParams();
    params = params.append('productId', object.productId.toString());
    params = params.append('startDate', object.startDate.toISOString());
    return this.http.get<number>(`${this.url}Prescription/GetPrescriptionIdByProductId`, { params: params});
  }

  addPrescriptionDetail(item: PrescriptionDetail): Observable<PrescriptionDetail> {
    return this.http.post<PrescriptionDetail>(`${this.url}Prescription/AddDetail`, item);
  }

  addPrescription(item: Prescription): Observable<Prescription> {
    return this.http.post<Prescription>(`${this.url}Prescription/Add`, item);
  }

  addManufacturePlain(item: ManufacturePlain): Observable<ManufacturePlain> {
    return this.http.post<ManufacturePlain>(`${this.url}Manufacture/AddPlain`, item);
  }

  removeManufacturePlain(item: ManufacturePlain): Observable<ManufacturePlain> {
    return this.http.post<ManufacturePlain>(`${this.url}Manufacture/RemovePlain`, item);
  }

  deletePrescription(item: Prescription): Observable<Prescription> {
    return this.http.post<Prescription>(`${this.url}Prescription/DeletePrescription`, item);
  }

  updateProductCritical(item: Product): Observable<Product> {
    return this.http.post<Product>(`${this.url}Product/UpdateCritical`, item);
  }

  updatePrescriptionDetail(item: PrescriptionDetail): Observable<PrescriptionDetail> {
    return this.http.put<PrescriptionDetail>(`${this.url}Prescription/UpdateDetail`, item);
  }

  deletePrescriptionDetail(item: PrescriptionDetail): Observable<PrescriptionDetail> {
    return this.http.post<PrescriptionDetail>(`${this.url}Prescription/DeleteDetail`, item);
  }

  completeManufacture(manufacture: Manufacture): Observable<Manufacture> {
    return this.http.post<Manufacture>(`${this.url}Manufacture/Complete`, manufacture);
  }

  outageProduct(product: Product, transferNote: string): Observable<Product> {
    let param = new HttpParams().set("product", JSON.stringify(product)).set("note", transferNote);
    return this.http.get<Product>(`${this.url}Product/OutageProduct`, { params: param });
  }


  getProductList(x): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.url}Product`);
  }

  getProductTypeList(): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(`${this.url}Product/GetTypeList`);
  }

  getProductUnitList(): Observable<Unit[]> {
    return this.http.get<Unit[]>(`${this.url}Product/GetUnitList`);
  }

  productSearchCodeOrName(key: string, type: number, warehouseId: number): Observable<Product[]> {
    let param = new HttpParams().set("key", key).set("type", type.toString()).set("warehouseId", warehouseId.toString());
    return this.http.get<Product[]>(`${this.url}Product/SearchCodeOrName`, { params: param });
  }

  addProduct(product: Product): Observable<Product> {
    return this.http.post<Product>(`${this.url}Product/AddProduct`, product);
  }

  startManufacturePlain(manufacture: Manufacture): Observable<Manufacture> {
    return this.http.post<Manufacture>(`${this.url}Manufacture/StartPlain`, manufacture);
  }


  getWarehouseList(): Observable<Warehouse[]> {
    return this.http.get<Warehouse[]>(`${this.url}Warehouse`);
  }
  getWarehouseQuantityListById(id: number): Observable<WarehouseQuantity[]> {
    return this.http.get<WarehouseQuantity[]>(`${this.url}Warehouse/GetDetailListById/${id}`);
  }
  addWarehouse(item: Warehouse): Observable<Warehouse> {
    return this.http.post<Warehouse>(`${this.url}Warehouse`, item);
  }

  getProductTransactionListByDateAndProductId(startDate: Date, endDate: Date, productId: number): Observable<ProductTransaction[]> {
    let object = { date1: startDate, date2: endDate }
    let params = new HttpParams();
    params = params.append('startDate', object.date1.toISOString());
    params = params.append('endDate', object.date2.toISOString());
    params = params.append('productId', productId.toString());
    return this.http.get<ProductTransaction[]>(`${this.url}Product/GetTransactionsByDateAndProductId`, { params: params });
  }

  UpdateAppearInReport(Id: number): Observable<boolean> {
    let object = { id : Id }
    let params = new HttpParams();
    params = params.append('id', object.id.toString());
    return this.http.post<boolean>(`${this.url}Product/UpdateAppearInReport`, object.id);
  }

  getManufactureCountByDateAndProductId(startDate: Date, endDate: Date, productId: number): Observable<ProductTransaction[]> {
    let object = { date1: startDate, date2: endDate }
    let params = new HttpParams();
    params = params.append('startDate', object.date1.toISOString());
    params = params.append('endDate', object.date2.toISOString());
    params = params.append('productId', productId.toString());
    return this.http.get<ProductTransaction[]>(`${this.url}Product/GetManufactureCountByDateAndProductId`, { params: params });
  }

  getInvoiceList(): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(`${this.url}Invoice`);
  }

  getTransferList(startDate: Date, endDate: Date, warehouseId: number): Observable<ProductTransactionList[]> {
    let object = { date1: startDate, date2: endDate }
    let params = new HttpParams();
    params = params.append('startDate', object.date1.toISOString());
    params = params.append('endDate', object.date2.toISOString());
    params = params.append('warehouseId', warehouseId.toString());
    return this.http.get<ProductTransactionList[]>(`${this.url}Warehouse/GetTransferList`, { params: params });
  }

  addWarehouseTransfer(list: WarehouseTransfer[], transferNote: string): Observable<WarehouseTransfer[]> {
    let params = new HttpParams();
    params = params.append('transferList', JSON.stringify(list));
    params = params.append('note', transferNote);
    return this.http.get<WarehouseTransfer[]>(`${this.url}Warehouse/AddTransfer`, { params: params });
  }

  getUserPageAuth(pageName): Observable<any> {
    return this.http.get<any>(`${this.url}User/GetAuthorizations/${pageName}`,);
  }

  getInvoiceDetailList(invoiceId: number): Observable<InvoiceDetail[]> {
    return this.http.get<InvoiceDetail[]>(`${this.url}Invoice/GetDetailListById/${invoiceId}`);
  }

  getManufacturePlainListByDate(startDate: Date, endDate: Date, warehouseId: number): Observable<ManufacturePlain[]> {
    let object = { date1: startDate, date2: endDate }
    let params = new HttpParams();
    params = params.append('startDate', object.date1.toISOString());
    params = params.append('endDate', object.date2.toISOString());
    params = params.append('warehouseId', warehouseId.toString());
    return this.http.get<ManufacturePlain[]>(`${this.url}ManufacturePlain/GetList`, { params: params });
  }
  
  getManufactureListByProductId(startDate: Date, endDate: Date, productId: number): Observable<number> {
    let object = { date1: startDate, date2: endDate }
    let params = new HttpParams();
     params = params.append('startDate', object.date1.toISOString());
     params = params.append('endDate', object.date2.toISOString());
    params = params.append('productId', productId.toString());
    return this.http.get<number>(`${this.url}Manufacture/GetListByProductId`, { params: params });
  }

  getListManufactureQuantity(startDate: Date, endDate: Date, warehouseId: number): Observable<ProductTransactionQuantity[]> {
    let object = { date1: startDate, date2: endDate }
    let params = new HttpParams();
     params = params.append('startDate', object.date1.toISOString());
     params = params.append('endDate', object.date2.toISOString());
     params = params.append('warehouseId', warehouseId.toString());
    return this.http.get<ProductTransactionQuantity[]>(`${this.url}Manufacture/GetListManufactureQuantity`, { params: params });
  }
  
  getListFaultyControlByProductId(startDate: Date, endDate: Date, productCode: string): Observable<number> {
    let object = { date1: startDate, date2: endDate }
    let params = new HttpParams();
     params = params.append('startDate', object.date1.toISOString());
     params = params.append('endDate', object.date2.toISOString());
    params = params.append('productCode', productCode);
    return this.http.get<number>(`${this.url}Manufacture/GetListFaultyControlByProductId`, { params: params });
  }
  getListScrapByProductId(startDate: Date, endDate: Date, productCode: string): Observable<number> {
    let object = { date1: startDate, date2: endDate }
    let params = new HttpParams();
     params = params.append('startDate', object.date1.toISOString());
     params = params.append('endDate', object.date2.toISOString());
    params = params.append('productCode', productCode);
    return this.http.get<number>(`${this.url}Manufacture/GetListScrapByProductId`, { params: params });
  }

  getManufacturePlainListByYearAndMonth(year: number, month: number): Observable<number[]> {
    let object = { year: year, month: month }
    let params = new HttpParams();
    params = params.append('year', object.year.toString());
    params = params.append('month', object.month.toString());
    return this.http.get<number[]>(`${this.url}ManufacturePlain/GetByYearMonth`, { params: params });
  }

  getManufactureFuturePlainList(startDate: Date, endDate: Date): Observable<PlainFuture[]> {
    let object = { date1: startDate, date2: endDate }
    let params = new HttpParams();
    params = params.append('startDate', object.date1.toISOString());
    params = params.append('endDate', object.date2.toISOString());
    return this.http.get<PlainFuture[]>(`${this.url}ManufacturePlain/GetFutureList`, { params: params });
  }



  addInvoice(item: Invoice): Observable<Invoice> {

    return this.http.post<Invoice>(`${this.url}Invoice/Add`, item);
  }
  syncProduct(): Observable<any> {
    return this.http.get(`${this.url}Sync`);
  }


  auth(login: Login): Promise<TokenModel> {
    return this.http.post<TokenModel>(`${this.url}Auth`, login).toPromise();
  }

  refreshToken(token: TokenModel): Promise<TokenModel> {
    return this.http.post<TokenModel>(`${this.url}Auth/RefreshTokenLogin`, token).toPromise();
  }


}
